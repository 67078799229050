import { Enumeration } from '@bp/shared/models/core/enum';

export class UsecaseCategory extends Enumeration {

	static eCommerce = new UsecaseCategory('eCommerce');

	static travel = new UsecaseCategory();

	static gaming = new UsecaseCategory();

	static saas = new UsecaseCategory('Subscriptions/Saas');

	static marketplaces = new UsecaseCategory();

}

export type UsecaseCardDto = {
	title: string;
	text: string;
	category: UsecaseCategory;
	categoryIconSrc: string;
	coverImgSrc: string;
	learnMoreRouterLink: string;
};
