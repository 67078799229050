import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bp-default-page-shell',
	templateUrl: './default-page-shell.component.html',
	styleUrls: [ './default-page-shell.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalPageComponent {

	@Input() title!: string;

	@Input() hasHeader = true;

}
