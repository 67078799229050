<button
	class="button"
	color="primary"
	mat-icon-button
	[matMenuTriggerFor]="socialsMenu"
	(click)="$event.stopPropagation(); $event.preventDefault()"
>
	<bp-svg-icon name="share"></bp-svg-icon>
</button>

<mat-menu #socialsMenu="matMenu" class="socials-panel">
	<button class="copy-link social reset-default-decoration" (click)="copyLink()">
		<bp-svg-icon name="copy-link"></bp-svg-icon>

		<span class="ml-2">Copy Link</span>
	</button>

	<a
		*ngFor="let social of socials"
		class="social reset-default-decoration"
		bpTargetBlank
		[href]="social | socialShareLink: title:link"
	>
		<bp-svg-icon [name]="social.name"></bp-svg-icon>

		<span class="ml-2">{{ social.displayName }}</span>
	</a>
</mat-menu>
