<div class="logo-section">
	<a mat-button class="logo-btn" color="primary" routerLink="/">
		<bp-svg-icon name="logo"></bp-svg-icon>
	</a>
</div>

<div class="left-section">
	<div>
		<a mat-button routerLink="/product">Product</a>

		<a mat-button routerLink="/bridger-retry">Bridger Retry™</a>

		<a mat-button routerLink="/usecases">Use Cases</a>

		<a mat-button routerLink="/payment-orchestration">Payment Operations</a>
	</div>

	<div class="ml-3">
		<a mat-button routerLink="/about-us">About Us</a>

		<a mat-button href="https://docs.bridgerpay.com/" bpTargetBlank>Developers</a>

		<a mat-button routerLink="/careers">
			<div class="text-left">Careers</div>
			<bp-we-are-hiring-badge></bp-we-are-hiring-badge>
		</a>

		<a mat-button routerLink="/newsroom">Newsroom</a>

		<a mat-button href="https://help.bridgerpay.com/">Help center</a>
	</div>
</div>

<div class="main-section flex-align-items-center">
	<p data-nosnippet class="description text-center">
		BridgerPay is the world’s first Payment Operations Platform, built to automate the payment flows of your
		business with a Lego-like interface, so you can connect any payment method or tool, boost revenue, and optimize
		your payment experience.
	</p>

	<div class="socials">
		<a href="https://www.linkedin.com/company/bridgerpay/" bpTargetBlank>
			<bp-svg-icon name="linkedin-white"></bp-svg-icon>
		</a>

		<a href="https://www.facebook.com/bridgerpay/" bpTargetBlank>
			<bp-svg-icon name="facebook-white"></bp-svg-icon>
		</a>

		<a href="https://www.instagram.com/bridgerpay/" bpTargetBlank>
			<bp-svg-icon name="instagram-white"></bp-svg-icon>
		</a>

		<a href="https://www.youtube.com/channel/UCZvt3egNCg7rMnaCwuldW4Q" bpTargetBlank>
			<bp-svg-icon name="youtube-white"></bp-svg-icon>
		</a>
	</div>

	<div class="address text-center">
		Eleftherias street, 153<br />
		Limassol, 3042, Cyprus
	</div>
	<bp-wizard-link classList="promo-flat-button" bpGoogleTagOnClick="promo_footer_cta_click">
		Start for Free
	</bp-wizard-link>
</div>

<div class="right-section">
	<div class="reliability-icons">
		<bp-picture src="{{ reliabilityIconsAssetsDir }}/visa"></bp-picture>
		<bp-picture src="{{ reliabilityIconsAssetsDir }}/mc"></bp-picture>
		<bp-picture src="{{ reliabilityIconsAssetsDir }}/visa-secure"></bp-picture>
		<bp-picture src="{{ reliabilityIconsAssetsDir }}/pci-dss"></bp-picture>
		<bp-picture src="{{ reliabilityIconsAssetsDir }}/ssl"></bp-picture>
		<div class="flex-break m-0"></div>
		<a href="http://discovery.ariba.com/profile/AN01733395394" bpTargetBlank class="ariba">
			<bp-picture src="{{ assetsDir }}/ariba"></bp-picture>
		</a>
	</div>
</div>

<div data-nosnippet class="workflow-description">
	BridgerPay is not a PSP (payment service provider), or an acquiring service, and we do not provide any processing
	merchant accounts. Bridger is a SaaS (software-as-a-service) company that allows businesses to utilise one API to
	consume all payments from any method or provider that is connected within BridgerPay’s ecosystem.
</div>

<div class="misc-section flex-align-items-center">
	<div class="mat-small">
		All Rights Reserved © BridgerPay

		<bp-app-version></bp-app-version>
	</div>

	<div class="misc-links">
		<a
			*ngFor="let page of legalPages; let last = last"
			routerLink="/{{ page.kebabName }}"
			class="link use-current-color bottom-bordered mat-small"
			[class.mr-4]="!last"
		>
			{{ page.displayName }}
		</a>
	</div>
</div>

<bp-footer-mock-links class="service-section ml-auto"></bp-footer-mock-links>
