import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'bp-companies-tiles',
	templateUrl: './companies-tiles.component.html',
	styleUrls: [ './companies-tiles.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompaniesTilesComponent {

	companiesTilesImgsSrcs = [
		'astropay', 'paypal', 'mc', 'shopify', 'klarna', 'csmoney', 'ingenico', 'magento', 'visa',
	]
		.map(name => `/assets/shared/companies-logos/${ name }`);

}
