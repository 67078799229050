<div class="header">
	<h2 class="title uppercase">PAYMENT OPERATIONS THAT SCALE WITH YOU</h2>

	<span class="mission"> Our mission is to solve all payment processing challenges forever. </span>
</div>

<div class="characteristics-wrapper">
	<div class="characteristics">
		<section *ngFor="let card of cards" class="characteristic">
			<div class="card-header mb-2">
				<bp-picture src="/assets/home/next-gen-operations/{{ card.icon }}" svg class="icon mr-2"></bp-picture>
				<span class="title uppercase">{{ card.title }}</span>
			</div>
			<p class="mb-1">{{ card.text }}</p>
			<a *ngIf="card.link" [routerLink]="card.link" class="link">Read More</a>
		</section>
	</div>

	<bp-picture src="/assets/home/next-gen-operations/operating-people" responsive></bp-picture>
</div>
