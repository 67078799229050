import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { StorageService } from '@bp/shared/services';

const CONSENT_ACCEPTED = 'consent-accepted';

@Injectable({ providedIn: 'root' })
export class CookiesUsageConsentService {

	consented$ = new BehaviorSubject<boolean>(!!this._storageService.get(CONSENT_ACCEPTED));

	constructor(private readonly _storageService: StorageService) {
	}

	consent() {
		this.consented$.next(true);

		this._storageService.set(CONSENT_ACCEPTED, true);
	}

}
