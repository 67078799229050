import { isScullyRunning } from '@scullyio/ng-lib';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FADE_IN } from '@bp/shared/animations';
import { Article } from '@bp/shared/domains/articles';

import { SocialNetwork } from '../../models';
import { ScullyPageInvalidWatcherViewService } from '../../services';

@Component({
	selector: 'bp-article[article][content]',
	templateUrl: './article.component.html',
	styleUrls: [ './article.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
	viewProviders: [ ScullyPageInvalidWatcherViewService ],
})
export class ArticleComponent {

	@Input() article!: Article | null;

	@Input() pending: boolean | null = true;

	@Input() content!: string | null | undefined;

	socials: SocialNetwork[] = [ SocialNetwork.facebook, SocialNetwork.linkedin, SocialNetwork.twitter ];

	isScullyRunning = isScullyRunning();

	// We are storing the result on the component construction, since the router instance will be changed at ngOnInit.
	routerNavigated = this._router.navigated;

	constructor(
		public route: ActivatedRoute,
		private readonly _router: Router,
		private readonly _scullyPageInvalidWatcher: ScullyPageInvalidWatcherViewService,
	) {
		this._scullyPageInvalidWatcher.watch();
	}

}
