<bp-svg-icon name="logo"> </bp-svg-icon>

<div class="mat-headline">
	<div class="work-smarter-text">{{ headliner }}</div>
	<div>STARTING TODAY</div>
</div>

<p class="free-trial">Free Forever | No credit card needed</p>

<bp-wizard-link classList="promo-flat-button" bpGoogleTagOnClick="promo_work-smarter_cta_click">
	Start for Free
</bp-wizard-link>
