import { distinctUntilChanged, filter, map } from 'rxjs';
import confetti, { CreateTypes, Options } from 'canvas-confetti';

import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';

import { PlayWhenPartiallyInViewportAnimationStrategy } from '@bp/promo/shared';

const CASHIER_ANIMATION_TRANSACTION_APPROVED_FRAME = 180;

@Component({
	selector: 'bp-build-payment-flows',
	templateUrl: './build-payment-flows.component.html',
	styleUrls: [ './build-payment-flows.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildPaymentFlowsComponent extends Destroyable implements AfterViewInit {

	@ViewChild('confettiCanvas') canvas!: ElementRef<HTMLCanvasElement>;

	animationStrategy = new PlayWhenPartiallyInViewportAnimationStrategy(0.9);

	private _customConfetti!: CreateTypes;

	constructor() {
		super();

		this._onAnimationReachEndLaunchConfetti();
	}

	ngAfterViewInit(): void {
		this._customConfetti = confetti.create(this.canvas.nativeElement, { useWorker: true, resize: true });
	}

	private _onAnimationReachEndLaunchConfetti(): void {
		this.animationStrategy.onFrameChange$
			.pipe(
				map(({ currentTime: currentFrame }) => currentFrame > CASHIER_ANIMATION_TRANSACTION_APPROVED_FRAME),
				distinctUntilChanged(),
				filter(fireConfetti => fireConfetti),
				takeUntilDestroyed(this),
			)
			.subscribe(() => {
				this._fireConfetti(0.25, {
					spread: 26,
					startVelocity: 35,
				});

				this._fireConfetti(0.2, {
					spread: 60,
					startVelocity: 25,
				});

				this._fireConfetti(0.35, {
					spread: 100,
					decay: 0.91,
					startVelocity: 35,
				});

				this._fireConfetti(0.1, {
					spread: 120,
					startVelocity: 15,
					decay: 0.92,
				});

				this._fireConfetti(0.1, {
					spread: 120,
					startVelocity: 25,
				});
			});
	}

	private _fireConfetti(particleRatio: number, options: Options): void {
		void this._customConfetti({
			origin: { y: 0.7 },
			colors: [ '#2578ea', '#33ff99', '#9999ff', '#ff6666' ],
			zIndex: 99_999,
			...options,
			particleCount: Math.floor(400 * particleRatio),
			ticks: 130,
			scalar: 0.5,
		});
	}

}
