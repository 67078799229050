<h2 class="headliner uppercase">
	A TECHNOLOGY THAT <span class="save">SAVES <bp-svg-icon name="check-yes"></bp-svg-icon></span> DECLINED PAYMENTS
</h2>

<p>
	Bridger Retry™ is a provider-agnostic technology that retries declined card payments seamlessly, increases your
	revenue, and retains more customers!
</p>

<bp-wizard-link
	classList="promo-flat-button"
	bpGoogleTagOnClick="promo_build-payment-flows_cta_click"
>
	Start for Free
</bp-wizard-link>

<div class="animation-wrapper">
	<bp-lottie-scene sceneName="home-cashier" looped [animationStrategy]="animationStrategy"></bp-lottie-scene>
	<canvas #confettiCanvas class="confettiCanvas"></canvas>
</div>
