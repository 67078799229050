import { Enumeration } from '@bp/shared/models/core/enum';

export class LegalPage extends Enumeration {

	static subscriptionTerms = new LegalPage('Subscription Terms');

	static cookiesPolicy = new LegalPage('Cookies Policy');

	static privacyPolicy = new LegalPage('Privacy Policy');

	static termsOfService = new LegalPage('Terms of Service');

	static prohibitedBusinesses = new LegalPage('Prohibited Businesses');

}
