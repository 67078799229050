import { Directive, forwardRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import { uuid } from '@bp/shared/utilities';

import { IModalHostComponent } from './modal-host-component.interface';
import { ModalComponent } from './modal.component';
import { ModalService } from './modal.service';

@Directive()
export abstract class ModalHostComponent extends Destroyable implements IModalHostComponent {

	id: string = uuid();

	panelClass?: string[];

	// eslint-disable-next-line @angular-eslint/no-forward-ref
	@ViewChild(forwardRef(() => ModalComponent), { static: true })
	modal!: ModalComponent;

	dialog?: MatDialogRef<any>;

	constructor(protected readonly _modalService: ModalService) {
		super();

		this._findDialogRefByIdAndSetIt();
	}

	closeModal(): void {
		this.dialog?.close();
	}

	private _findDialogRefByIdAndSetIt(): void {
		this._modalService
			.getOpenDialogById$(this.id)
			.pipe(takeUntilDestroyed(this))
			.subscribe(dialog => (this.dialog = dialog));
	}
}
