
import type { MetaDefinition } from '@angular/platform-browser';

import { MapFromDTO } from '@bp/shared/models/metadata';
import { isNotEmpty } from '@bp/shared/utilities';

import { Metatags } from './metatags';

export class VideoMetatags extends Metatags {

	@MapFromDTO()
	url?: string;

	@MapFromDTO()
	type?: string;

	@MapFromDTO()
	duration?: number;

	@MapFromDTO()
	width?: number;

	@MapFromDTO()
	height?: number;

	@MapFromDTO()
	tags?: string[];

	readonly metatagsSchema = 'og:video';

	getMetaDefinitions(): MetaDefinition[] {
		const definitions: MetaDefinition[] = [];

		if (this.url) {
			definitions.push(
				{
					property: `${ this.metatagsSchema }:url`,
					content: this.url,
				},
				{
					property: `${ this.metatagsSchema }:secure_url`,
					content: this.url,
				},
			);
		}

		if (this.type) {
			definitions.push({
				property: `${ this.metatagsSchema }:type`,
				content: this.type,
			});
		}

		if (isNotEmpty(this.duration)) {
			definitions.push({
				property: `${ this.metatagsSchema }:duration`,
				content: this.duration.toString(),
			});
		}

		if (isNotEmpty(this.width)) {
			definitions.push({
				property: `${ this.metatagsSchema }:width`,
				content: this.width.toString(),
			});
		}

		if (isNotEmpty(this.height)) {
			definitions.push({
				property: `${ this.metatagsSchema }:height`,
				content: this.height.toString(),
			});
		}

		if (isNotEmpty(this.tags))
			this.tags.forEach(tag => definitions.push({ property: `${ this.metatagsSchema }:tag`, content: tag }));

		return definitions;
	}

}
