import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bp-work-smarter',
	templateUrl: './work-smarter.component.html',
	styleUrls: [ './work-smarter.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkSmarterComponent {

	@Input() headliner = 'PAYMENT OPERATIONS';

}
