import { set } from 'lodash-es';
import type { Observable, Subscription } from 'rxjs';
import { fromEvent, Subject } from 'rxjs';

import type { Direction, Vector } from '@bp/shared/models/core';
import { Point } from '@bp/shared/models/core';
import type { ZoneService } from '@bp/shared/rxjs';
import { observeInsideNgZone } from '@bp/shared/rxjs';

const DOUBLE_TAP_TIME = 250;
const LONG_TAP_TIME = 750;
const MOVE_MIN_LENGTH = 10;
const SWIPE_MIN_LENGTH = 30;

enum TimeoutType {

	SingleTap,

	LongTap,

}

export class TouchManager {

	static readonly events = <const> [ // TODO: Change to string enums after TS 2.4 release
		'touchStart',
		'touchMove',
		'touchEnd',
		'touchCancel',
		'tap',
		'singleTap',
		'doubleTap',
		'longTap',
		'pan',
		'swipe',
		'pinch',
		'rotate',
	];

	touchStart$!: Observable<TouchEvent>;

	touchMove$!: Observable<TouchEvent>;

	touchEnd$!: Observable<TouchEvent>;

	touchCancel$!: Observable<TouchEvent>;

	tap$!: Observable<TouchEvent>;

	singleTap$!: Observable<TouchEvent>;

	doubleTap$!: Observable<TouchEvent>;

	longTap$!: Observable<TouchEvent>;

	pan$!: Observable<IPanEvent>;

	swipe$!: Observable<ISwipeEvent>;

	pinch$!: Observable<IPinchEvent>;

	rotate$!: Observable<IRotateEvent>;

	private _isMoved!: boolean;

	private _isDoubleTap!: boolean;

	private _startPosition?: Point;

	private _lastPosition!: Point;

	private _lastVector?: Vector;

	// Position at previous tap
	private _prevPosition?: Point;

	private _prevTime!: Date;

	private readonly _timeouts = new Map<TimeoutType, number>();

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	private readonly _subjects: {
		[K in typeof TouchManager.events[number] ]: Subject<TouchEvent>
	} = <any>{};

	private readonly _subscriptions: Subscription[] = [];

	constructor($element: Element, zoneService: ZoneService) {

		this._subscriptions.push(...zoneService.runOutsideAngular(() => [
			fromEvent<TouchEvent>($element, 'touchstart')
				.subscribe(event => void this._onStart(event)),

			fromEvent<TouchEvent>($element, 'touchmove')
				.subscribe(event => void this._onMove(event)),

			fromEvent<TouchEvent>($element, 'touchend')
				.subscribe(event => void this._onEnd(event)),

			fromEvent<TouchEvent>($element, 'touchcancel')
				.subscribe(event => void this._onCancel(event)),
		]));

		TouchManager.events.forEach(event => {
			this._subjects[event] = new Subject();

			set(this, `${ event }$`, this._subjects[event].pipe(observeInsideNgZone()));
		});
	}

	destroy() {
		this._cancel();

		this._subscriptions.forEach(s => void s.unsubscribe());

		TouchManager.events.forEach(event => void this._subjects[event].complete());
	}

	private _onStart(event: TouchEvent) {
		this._subjects.touchStart.next(event);

		const pos = new Point(event.touches[0].pageX, event.touches[0].pageY);
		const now = new Date();

		if (event.touches.length === 1) {
			// Reset values
			this._isMoved = false;

			this._isDoubleTap = false;

			this._startPosition = pos;

			// Handle double tap
			if (this._prevPosition) {
				const elapsed = Number(now) - Number(this._prevTime);

				this._isDoubleTap = elapsed <= DOUBLE_TAP_TIME;
			}

			this._timeouts.set(TimeoutType.LongTap, Number(setTimeout(() => void this._subjects.longTap.next(event), LONG_TAP_TIME)));
		} else {
			!this._startPosition && (this._startPosition = pos);
			const second = new Point(event.touches[1].pageX, event.touches[1].pageY);

			this._lastVector = second.diff(pos);
		}

		this._prevPosition = this._lastPosition = pos;

		this._prevTime = now;
	}

	private _onMove(event: TouchEvent) {
		this._subjects.touchMove.next(event);

		const pos = new Point(event.touches[0].pageX, event.touches[0].pageY);
		const move = this._startPosition!.diff(pos);

		if (move.length() >= MOVE_MIN_LENGTH) {
			this._isMoved = true;

			this._cancel(TimeoutType.SingleTap);

			this._cancel(TimeoutType.LongTap);
		}

		if (this._isMoved) {
			if (event.touches.length > 1) {
				const second = new Point(event.touches[1].pageX, event.touches[1].pageY);
				const vector = second.diff(pos);

				if (this._lastVector) {
					const previousLength = this._lastVector.length();

					if (previousLength > 0) {
						(<IPinchEvent> event).bpScale = vector.length() / previousLength;

						this._subjects.pinch.next(event);
					}

					(<IRotateEvent> event).bpAngle = vector.getAngleDegree(this._lastVector);

					this._subjects.rotate.next(event);
				}

				this._lastVector = vector;
			} else {
				const pe = <IPanEvent> event;

				pe.bpDeltaX = pos.x - this._startPosition!.x;

				pe.bpDeltaY = pos.y - this._startPosition!.y;

				this._subjects.pan.next(pe);
			}

			this._lastPosition = pos;
		}

		if (this._subjects.pinch.observed || this._subjects.rotate.observed || this._subjects.pan.observed)
			event.preventDefault();
	}

	private _onEnd(event: TouchEvent) {
		this._cancel(TimeoutType.LongTap);

		this._subjects.touchEnd.next(event);

		if (event.touches.length > 0)
			return;

		const move = this._lastPosition.diff(this._startPosition!);

		// Swipe
		if (move.length() > SWIPE_MIN_LENGTH) {
			this._cancel(TimeoutType.SingleTap);

			(<ISwipeEvent> event).bpDirection = move.direction();

			this._subjects.swipe.next(event);
			// Tap
		} else if (!this._isMoved) {
			this._subjects.tap.next(event);

			if (this._isDoubleTap) {
				this._cancel(TimeoutType.SingleTap);

				this._subjects.doubleTap.next(event);
			} else
				this._timeouts.set(TimeoutType.SingleTap, Number(setTimeout(() => void this._subjects.singleTap.next(event), DOUBLE_TAP_TIME)));

		}
	}

	private _onCancel(event: TouchEvent) {
		this._cancel();

		this._subjects.touchCancel.next(event);
	}

	private _cancel(type?: TimeoutType) {
		type ? clearTimeout(this._timeouts.get(type)) : this._timeouts.forEach(clearTimeout);
	}
}

export interface IRotateEvent extends TouchEvent {
	bpAngle: number;
}

export interface IPinchEvent extends TouchEvent {
	bpScale: number;
}

export interface ISwipeEvent extends TouchEvent {
	bpDirection: Direction;
}

export interface IPanEvent extends TouchEvent {
	bpDeltaX: number;
	bpDeltaY: number;
}
