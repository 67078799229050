<h1 class="headliner uppercase">
	A PAYMENT OPERATIONS
	<span class="build">
		PLATFORM<bp-lottie-scene
			sceneName="home-check"
			[animationStrategy]="commonAnimationStrategy"
			noFadeIn
			eager
		></bp-lottie-scene>
	</span>
	<br />
	BUILT TO SCALE&nbsp;<bp-lottie-scene
		sceneName="home-vertical-cards"
		looped
		noFadeIn
		eager
		[animationStrategy]="commonAnimationStrategy"
	></bp-lottie-scene
	>&nbsp;ANY BUSINESS
</h1>

<p>Easily build, manage, and optimize your connections to any payment gateway or method worldwide.</p>

<bp-wizard-link
	classList="promo-flat-button"
	bpGoogleTagOnClick="promo_above-fold_cta_click"
>
	Start for Free
</bp-wizard-link>

<bp-above-fold-secure [assetsDir]="assetsDir"></bp-above-fold-secure>

<bp-above-fold-video
	bpOpenYoutubePlayerDialogOnClick="IbwBjaacHU4"
	bpOpenYoutubePlayerDialogOnClickLooped
	[assetsDir]="assetsDir"
></bp-above-fold-video>

<div class="media">
	<bp-lottie-scene
		sceneName="home-router"
		[animationStrategy]="routerAnimationStrategy"
		looped
		noFadeIn
		eager
	></bp-lottie-scene>
</div>
