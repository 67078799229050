import type { AnimationItem } from 'lottie-web-light';
import type { Observable } from 'rxjs';
import { range } from 'lodash-es';

import { Directive } from '@angular/core';

import { fromViewportIntersection } from '@bp/shared/rxjs';
import { takeUntilDestroyed } from '@bp/shared/models/common';

import type { ILottieAnimationStrategy } from './lottie-animation-strategy';
import { BaseAnimationStrategy } from './base-animation-strategy';

@Directive() // To satisfy compiler
export class RelatedToViewportIntersectionAnimationStrategy extends BaseAnimationStrategy implements ILottieAnimationStrategy {

	override apply($host: HTMLElement, animationItem: AnimationItem): void {
		this._observeHostScrollingInsideViewport($host)
			.pipe(takeUntilDestroyed(this))
			.subscribe(({ boundingClientRect, intersectionRatio }) => {
				if (boundingClientRect.top > 0)
					// 50 hardcoded value due to stupid animations provided by Ben, should be totalFrames
					animationItem.goToAndStop(intersectionRatio * 50, true);
			});
	}

	private _observeHostScrollingInsideViewport($host: HTMLElement): Observable<IntersectionObserverEntry> {
		return fromViewportIntersection(
			$host,
			{
				rootMargin: '0px 0px -25% 0px',
				threshold: range(0, 1, 0.01),
			},
		);
	}

}
