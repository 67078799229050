import type { AnimationItem } from 'lottie-web-light';
import type { Observable } from 'rxjs';
import { range } from 'lodash-es';

import { Directive } from '@angular/core';

import { fromViewportIntersection } from '@bp/shared/rxjs';
import { takeUntilDestroyed } from '@bp/shared/models/common';

import type { ILottieAnimationStrategy } from './lottie-animation-strategy';
import { BaseAnimationStrategy } from './base-animation-strategy';

@Directive() // To satisfy compiler
export class PlayWhenPartiallyInViewportAnimationStrategy extends BaseAnimationStrategy implements ILottieAnimationStrategy {

	constructor(private readonly _playThreshold: number) {
		super();
	}

	override apply($host: HTMLElement, animationItem: AnimationItem): void {
		super.apply($host, animationItem);

		this._observeHostEnterViewport($host)
			.pipe(takeUntilDestroyed(this))
			.subscribe(({ boundingClientRect, intersectionRatio, rootBounds }) => {
				if (!rootBounds)
					return;

				if (intersectionRatio >= this._playThreshold)
					animationItem.play();
				else if (boundingClientRect.top > rootBounds.height)
					animationItem.goToAndStop(0); // Reset when the host gets in the viewport from the top
			});
	}

	private _observeHostEnterViewport($host: HTMLElement): Observable<IntersectionObserverEntry> {
		return fromViewportIntersection(
			$host,
			{
				threshold: range(0, 1, 0.01),
			},
		);
	}

}
