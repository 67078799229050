<div class="title-wrapper" (click)="open = !open">
	<div class="title-text-wrapper">
		<ng-content select=".title"></ng-content>
	</div>

	<bp-picture src="/assets/shared/toggable-section/state-arrow" svg [class.open]="open"></bp-picture>
</div>

<div *ngIf="open" @slide class="data-wrapper">
	<div class="spacer">
		<ng-content select=".data"></ng-content>
	</div>
</div>
