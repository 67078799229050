<bp-modal hideCloseBtn>
	<ng-template>
		<nav class="main-menu">
			<a mat-button routerLinkNoOutlets="product" routerLinkActive="active"> Product </a>

			<a mat-button routerLinkNoOutlets="bridger-retry" routerLinkActive="active"> Retry™ </a>

			<a mat-button routerLinkNoOutlets="connections" routerLinkActive="active"> Connections </a>

			<a mat-button routerLinkNoOutlets="pricing" routerLinkActive="active"> Pricing </a>

			<bp-company-submenu class="togglable-submenu"></bp-company-submenu>

			<a mat-button routerLinkNoOutlets="learn" routerLinkActive="active"> Learn </a>
		</nav>

		<nav class="bottom-menu">
			<bp-wizard-link
				classList="promo-flat-button get-started-btn"
				bpGoogleTagOnClick="promo_modal-menu_cta_click"
			>
				Start for Free
			</bp-wizard-link>

			<div class="text-center">
				<a class="link" href="https://dash.bridgerpay.com" bpTargetBlank>Login</a>
			</div>
		</nav>
	</ng-template>
</bp-modal>
