import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesModalModule } from '@bp/shared/features/modal';
import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedFeaturesTextMaskModule } from '@bp/shared/features/text-mask';
import { SharedFeaturesValidationModule } from '@bp/shared/features/validation';
import { SharedFeaturesYoutubePlayerModule } from '@bp/shared/features/youtube-player';
import { SharedFeaturesAnalyticsModule } from '@bp/shared/features/analytics';
import { SharedPipesModule } from '@bp/shared/pipes';
import { SharedFeaturesCarouselModule } from '@bp/shared/features/carousel';

import { DescriptionBlockComponent, EmailContactFormComponent, GetStartedFormComponent, LegalPageComponent, LottieSceneComponent, RateComponent, ReviewFaceComponent, UniversalErrorPageComponent, UploadLinkComponent, UsecaseCardComponent, UsecasesPreviewCardComponent, WorkSmarterComponent, LegalPagesLinksComponent, CompaniesTilesComponent, GetStartedModalComponent, ToggableCardComponent, ToggableSectionComponent, PendingAnimationComponent, WizardLinkComponent, ArticleComponent, PublicationDateComponent, ShareButtonComponent, CarouselTestimonialsComponent, CarouselTestimonialComponent } from './components';
import { OverflowDetectorDirective, ParallaxDirective, PromoTooltipDirective } from './directives';
import { SocialShareLinkPipe } from './pipes';

const EXPOSED_MODULES = [
	CommonModule,
	HttpClientModule,
	MatButtonModule,
	MatFormFieldModule,
	MatInputModule,
	MatIconModule,
	MatSelectModule,

	SharedPipesModule,
	SharedDirectivesModule,
	SharedComponentsCoreModule,
	SharedFeaturesModalModule,
	SharedFeaturesValidationModule,
	SharedFeaturesTextMaskModule,
	SharedFeaturesSvgIconsModule,
	SharedFeaturesYoutubePlayerModule,
];

const EXPOSED_COMPONENTS = [
	ArticleComponent,
	LegalPagesLinksComponent,
	UsecaseCardComponent,
	UsecasesPreviewCardComponent,
	LegalPageComponent,
	EmailContactFormComponent,
	PendingAnimationComponent,
	DescriptionBlockComponent,
	RateComponent,
	ReviewFaceComponent,
	WorkSmarterComponent,
	ParallaxDirective,
	PromoTooltipDirective,
	GetStartedModalComponent,
	UploadLinkComponent,
	UniversalErrorPageComponent,
	LottieSceneComponent,
	GetStartedFormComponent,
	ToggableCardComponent,
	ToggableSectionComponent,
	WizardLinkComponent,
	SocialShareLinkPipe,
	ShareButtonComponent,
	OverflowDetectorDirective,
	CarouselTestimonialsComponent,
];

@NgModule({
	imports: [ EXPOSED_MODULES, ReactiveFormsModule, RouterModule, MatMenuModule, SharedFeaturesCarouselModule, SharedFeaturesAnalyticsModule ],
	exports: [ EXPOSED_MODULES, EXPOSED_COMPONENTS ],
	declarations: [
		...EXPOSED_COMPONENTS,
		CompaniesTilesComponent,
		PublicationDateComponent,
		CarouselTestimonialComponent,
	],
})
export class SharedModule {}
