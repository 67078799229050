<bp-svg-shared-icons-definitions></bp-svg-shared-icons-definitions>

<bp-svg-icons-definitions></bp-svg-icons-definitions>

<bp-modal-outlet>
	<router-outlet name="modal"></router-outlet>
</bp-modal-outlet>

<bp-cookies-usage-consent-popup></bp-cookies-usage-consent-popup>

<div class="container">
	<bp-header *ngIf="(isLanding$ | async) === false"></bp-header>

	<content
		class="page-outlet-container"
		[@fadeRouteHitAnimations]="shouldAnimateOutlet"
		(@fadeRouteHitAnimations.done)="shouldAnimateOutlet = false; routeAnimationObserver.done()"
	>
		<router-outlet #outlet (activate)="onActivate()"></router-outlet>
	</content>

	<bp-footer *ngIf="(isLanding$ | async) === false"></bp-footer>
</div>
