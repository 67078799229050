import { kebabCase } from 'lodash-es';

import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { attrBoolValue } from '@bp/shared/utilities';

@Component({
	selector: 'bp-description-block',
	templateUrl: './description-block.component.html',
	styleUrls: [ './description-block.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptionBlockComponent implements OnInit {

	@Input()
	@HostBinding('class.headliner')
	headliner!: boolean | '';

	@Input() header!: string;

	@Input() description!: string;

	@Input() btnRouterLinkText?: string;

	@Input() btnRouterLink?: any;

	@Input() btnRouterLinkFragment?: any;

	defaultGetStartedRouterLink = [ '/', { outlets: { modal: [ 'get-started' ]} }];

	callToActionGaEvent!: `promo_description-${ string }_cta_click`;

	ngOnInit(): void {
		this.headliner = attrBoolValue(this.headliner);

		this.callToActionGaEvent = `promo_description-${ kebabCase(this.header) }_cta_click`;

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		this.btnRouterLink = this.btnRouterLinkFragment && !this.btnRouterLink
			? '.'
			: (this.btnRouterLink ? this.btnRouterLink : this.defaultGetStartedRouterLink);
	}

}
