<form class="promo-form" (submit)="submit($event)">
	<mat-form-field appearance="outline" class="input-with-suffix-button-lg">
		<mat-label *ngIf="!noFloatLabel">
			{{ (media.greaterThan$('Phone') | async) ? fullPlaceholder : shortPlaceholder }}
		</mat-label>

		<input
			type="text"
			matInput
			autocomplete="email"
			bpTrimOnPaste
			[formControl]="emailCtrl"
			[placeholder]="
				noFloatLabel ? ((media.greaterThan$('Phone') | async) ? fullPlaceholder : shortPlaceholder) : ''
			"
		/>

		<button
			matSuffix
			mat-flat-button
			color="primary"
			type="submit"
			class="get-started-button promo-flat-button"
			bpGoogleTagOnClick="promo_email-contact-form_cta_click"
		>
			<span class="label">Start for Free</span>
		</button>

		<mat-error bpFieldError></mat-error>
	</mat-form-field>
</form>
