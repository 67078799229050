import { ClipboardService } from 'ngx-clipboard';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SocialNetwork } from '../../models';

@Component({
	selector: 'bp-share-button[title]',
	templateUrl: './share-button.component.html',
	styleUrls: [ './share-button.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareButtonComponent {

	@Input() title!: string;

	@Input() link = location.href;

	@Input() socials: SocialNetwork[] = [ SocialNetwork.facebook, SocialNetwork.linkedin, SocialNetwork.twitter ];

	constructor(private readonly _clipboardService: ClipboardService) {
	}

	copyLink(): void {
		this._clipboardService.copy(this.link);
	}

}
