<div class="info-block devs">
	<img src="{{ assetsDir }}/for-devs.svg" alt="For developers" />
	<h4>For Developers</h4>
	<p>Build your own payment stack with the BridgerPay API.</p>
	<a class="link nowrap" href="https://docs.bridgerpay.com/" bpTargetBlank>Access our documentation</a>
</div>

<div class="info-block pricing">
	<img src="{{ assetsDir }}/pricing.svg" alt="Pricing" />
	<h4>For Payment Providers</h4>
	<p>
		Interested in connecting your payment gateway or method into BridgerPay?

		<a class="link nowrap" [routerLink]="['/', { outlets: { modal: ['get-started', { type: 'partner' }] } }]">
			Register here
		</a>
	</p>
</div>
