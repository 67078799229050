import { Injectable } from '@angular/core';

import { UtmService } from '@bp/shared/features/analytics';
import { EnvironmentService, SessionStorageService } from '@bp/shared/services';
import { Dictionary } from '@bp/shared/typings';

const wizardConfigIdParamName = 'configId';
const appInitUrl = new URL(window.location.toString());

@Injectable({ providedIn: 'root' })
export class WizardConnectService {

	wizardConfigId: string | null = this._getWizardConfigId();

	constructor(
		private readonly _environmentService: EnvironmentService,
		private readonly _sessionStorageService: SessionStorageService,
		private readonly _utmService: UtmService,
	) {
		appInitUrl.searchParams.delete(wizardConfigIdParamName);
	}

	getWizardUrl(params?: Dictionary<string>): string {
		const wizardUrlWithQueryParams = new URL(this._environmentService.wizardUrl!);

		const wizardUrlQueryParams = new URLSearchParams([
			...this._utmService.getUtmSearchParamsEntries(),
			...Object.entries({ ...params }),
		]);

		if (this.wizardConfigId)
			wizardUrlQueryParams.append('id', this.wizardConfigId);

		wizardUrlWithQueryParams.search = wizardUrlQueryParams.toString();

		return wizardUrlWithQueryParams.toString();
	}

	private _getWizardConfigId(): string | null {
		const queryParamWizardConfigId = appInitUrl.searchParams.get(wizardConfigIdParamName);

		if (queryParamWizardConfigId)
			this._sessionStorageService.set(wizardConfigIdParamName, queryParamWizardConfigId);

		return this._sessionStorageService.get<string | null>(wizardConfigIdParamName);
	}

}
