import { ChangeDetectionStrategy, Component } from '@angular/core';

import { LegalPage } from '@bp/promo/shared';

@Component({
	selector: 'bp-footer',
	templateUrl: './footer.component.html',
	styleUrls: [ './footer.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {

	assetsDir = '/assets/footer';

	reliabilityIconsAssetsDir = '/assets/shared/reliability-icons';

	currentYear = new Date().getUTCFullYear();

	legalPages = LegalPage.getList();

}
