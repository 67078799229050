import { BehaviorSubject, observeOn } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Directive, ElementRef, OnInit } from '@angular/core';

import { BpScheduler, fromResize } from '@bp/shared/rxjs';
import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';

// Note that overflow could be detected even in case no scrollbar appears,
// e.g. by setting line-height lesser than specified amount (~1.1 or 1.2).

@Directive({
	selector: '[bpOverflowDetector]',
	exportAs: 'overflowDetector',
})
export class OverflowDetectorDirective extends Destroyable implements OnInit {

	private readonly _isOverflown$ = new BehaviorSubject(false);

	isOverflown$ = this._isOverflown$.pipe();

	private readonly _nativeElement = this._elementRef.nativeElement;

	constructor(private readonly _elementRef: ElementRef<HTMLElement>) {
		super();
	}

	ngOnInit(): void {
		this._onElementResizeUpdateOverflowStatus();
	}

	private _onElementResizeUpdateOverflowStatus(): void {
		fromResize(this._nativeElement)
			.pipe(
				map(() => (this._nativeElement.scrollHeight > this._nativeElement.clientHeight)
					|| (this._nativeElement.scrollWidth > this._nativeElement.clientWidth)),
				distinctUntilChanged(),
				observeOn(BpScheduler.inside),
				takeUntilDestroyed(this),
			)
			.subscribe(isOverflown => void this._isOverflown$.next(isOverflown));
	}

}
