<svg version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink">
	<defs>
		<!-- #region Social media -->
		<symbol id="linkedin" viewBox="0 0 24 24">
			<path
				d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
			/>
		</symbol>

		<symbol id="youtube" viewBox="0 0 24 24">
			<path
				d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
			/>
		</symbol>

		<symbol id="twitter" viewBox="0 0 24 24">
			<path
				d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
			/>
		</symbol>

		<symbol id="facebook" viewBox="0 0 24 24">
			<path
				d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
			/>
		</symbol>

		<symbol id="facebook-white" viewBox="0 0 24 25">
			<path
				fill="#fff"
				d="M17.98.15C21.296.15 24 2.855 24 6.17v11.96c0 3.315-2.705 6.02-6.02 6.02H6c-3.295 0-6-2.705-6-6V6.17C0 2.854 2.705.149 6 .149zm-2.484 8.955h-2.347V7.962c0-.591.363-.763.63-.763h1.66V4.664h-2.347c-2.575 0-3.185 1.925-3.185 3.145v1.296H8.57v3.05h1.526v7.434h2.862v-7.434h2.27z"
			/>
		</symbol>

		<symbol id="linkedin-white" viewBox="0 0 24 25">
			<path
				fill="#fff"
				d="M18.328 19.181h-2.812v-5.625c0-.775-.631-1.406-1.407-1.406-.775 0-1.406.631-1.406 1.406v5.625H9.891V9.337h2.812v.53c.737-.228 1.216-.53 2.11-.53 1.907.003 3.515 1.714 3.515 3.736zM8.484 7.931H5.672V5.12h2.812zm0 11.25H5.672V9.337h2.812zm12-19.031H3.516A3.52 3.52 0 0 0 0 3.666v16.968a3.52 3.52 0 0 0 3.516 3.516h16.968A3.52 3.52 0 0 0 24 20.634V3.666A3.52 3.52 0 0 0 20.484.15z"
			/>
		</symbol>

		<symbol id="youtube-white" viewBox="0 0 32 23">
			<path
				fill="#fff"
				d="M11.67 16.727V6.313l10.001 5.223-10.001 5.19zm19.246-12.33c-.385-1.673-1.754-2.907-3.401-3.091C23.614.87 19.665.868 15.735.87c-3.931-.002-7.88 0-11.782.436C2.307 1.49.94 2.724.554 4.397.006 6.78 0 9.381 0 11.834c0 2.453 0 5.054.548 7.436.385 1.673 1.752 2.907 3.4 3.091 3.9.436 7.85.438 11.78.436 3.932.002 7.88 0 11.78-.436 1.647-.184 3.016-1.418 3.401-3.091.548-2.383.55-4.983.55-7.436 0-2.453.005-5.054-.543-7.437z"
			/>
		</symbol>

		<symbol id="instagram-white" viewBox="0 0 24 25">
			<defs>
				<path id="a" d="M0 0h24v24H0z" />
			</defs>
			<g fill="none" fill-rule="evenodd">
				<g transform="translate(0 .15)">
					<mask id="b" fill="#fff">
						<use xlink:href="#a" />
					</mask>
					<path
						d="M18.72 6.985c-1.07 0-1.94-.87-1.94-1.939 0-1.07.87-1.94 1.94-1.94 1.069 0 1.939.87 1.939 1.94 0 1.07-.87 1.94-1.94 1.94M12 18.561A6.57 6.57 0 015.438 12 6.57 6.57 0 0112 5.438 6.57 6.57 0 0118.562 12 6.57 6.57 0 0112 18.562M17.503 0H6.497A6.504 6.504 0 000 6.497v11.007A6.504 6.504 0 006.497 24h11.007A6.504 6.504 0 0024 17.504V6.497A6.504 6.504 0 0017.503 0"
						fill="#FFF"
						mask="url(#b)"
					/>
				</g>
				<path
					d="M12 6.995a5.161 5.161 0 00-5.155 5.155A5.161 5.161 0 0012 17.305a5.161 5.161 0 005.155-5.155A5.161 5.161 0 0012 6.995M18.72 4.664a.533.533 0 100 1.066.533.533 0 000-1.066"
					fill="#FFF"
				/>
			</g>
		</symbol>

		<symbol id="share" viewBox="0 0 23 24">
			<path
				d="M18.603 16.696a3.612 3.612 0 0 0-2.6 1.12L7.199 13.41a3.228 3.228 0 0 0 0-2.022l8.818-4.485a3.698 3.698 0 1 0-.773-1.228l-8.731 4.442a3.611 3.611 0 1 0 0 4.572l8.71 4.384a3.56 3.56 0 0 0-.224 1.235 3.61 3.61 0 1 0 3.61-3.612h-.006Zm0-14.444a2.167 2.167 0 1 1 0 4.333 2.167 2.167 0 0 1 0-4.333ZM3.775 14.53a2.167 2.167 0 1 1 0-4.335 2.167 2.167 0 0 1 0 4.335Zm14.828 7.944a2.166 2.166 0 1 1 0-4.333 2.166 2.166 0 0 1 0 4.333Z"
			/>
		</symbol>

		<symbol id="copy-link" viewBox="0 0 22 15">
			<path
				d="M12.382 12.028a.3.3 0 0 0 0 .424l1.688 1.688a.3.3 0 0 0 .425 0l6.617-6.617a.3.3 0 0 0 0-.424L14.495.482a.3.3 0 0 0-.425 0L12.382 2.17a.3.3 0 0 0 0 .425l4.505 4.504a.3.3 0 0 1 0 .424zm-3.24-9.433a.3.3 0 0 0 0-.425L7.453.482a.3.3 0 0 0-.424 0L.412 7.1a.3.3 0 0 0 0 .424L7.03 14.14a.3.3 0 0 0 .424 0l1.689-1.688a.3.3 0 0 0 0-.424L4.637 7.523a.3.3 0 0 1 0-.424z"
			/>
		</symbol>
		<!-- #endregion Social media -->

		<!-- #region Utils -->
		<symbol id="arrow-drop-down" viewBox="0 0 24 24">
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path
				d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"
			/>
		</symbol>

		<symbol id="pdf" viewBox="0 0 29 29">
			<path
				d="M4.946 15.112a.923.923 0 01-.256-.64v-9.97c0-.48.184-.941.513-1.281A1.72 1.72 0 016.44 2.69h10.5a.87.87 0 01.623.26l6.114 6.344a.873.873 0 01.263.646v4.531c0 .24-.092.471-.256.641a.86.86 0 01-.619.265.86.86 0 01-.619-.265.923.923 0 01-.256-.64v-3.626h-5.25a.86.86 0 01-.619-.265.923.923 0 01-.256-.641V4.503H6.44v9.968c0 .24-.092.471-.256.641a.86.86 0 01-.619.265.86.86 0 01-.619-.265zm12.869-6.078h3.14l-3.14-3.251zm-10.5 9.062a2.58 2.58 0 011.856.797 2.77 2.77 0 01.769 1.922c0 .721-.277 1.413-.769 1.922a2.58 2.58 0 01-1.856.797H6.44v.906c0 .24-.092.47-.256.64a.86.86 0 01-.619.266.86.86 0 01-.619-.265.923.923 0 01-.256-.641v-5.438c0-.24.092-.47.256-.64a.86.86 0 01.619-.266zm0 3.625a.86.86 0 00.619-.265c.164-.17.256-.4.256-.641 0-.24-.092-.47-.256-.64a.86.86 0 00-.619-.266H6.44v1.812zm14.438-1.812v1.36h1.75a.86.86 0 01.618.265.923.923 0 010 1.282.86.86 0 01-.618.265h-1.75v1.359c0 .24-.093.47-.257.64a.86.86 0 01-.618.266.86.86 0 01-.62-.265.923.923 0 01-.255-.641v-5.438c0-.24.092-.47.256-.64a.86.86 0 01.619-.266h3.062a.86.86 0 01.619.266c.164.17.256.4.256.64s-.092.471-.256.641a.86.86 0 01-.619.266zm-7.438-1.813a3.44 3.44 0 012.475 1.062 3.692 3.692 0 011.025 2.563 3.69 3.69 0 01-1.025 2.563 3.44 3.44 0 01-2.475 1.062h-1.531a.86.86 0 01-.619-.265.923.923 0 01-.256-.641v-5.437c0-.24.092-.471.256-.641a.86.86 0 01.619-.266zm0 5.438c.464 0 .91-.191 1.237-.531.329-.34.513-.801.513-1.282 0-.48-.184-.942-.513-1.281a1.72 1.72 0 00-1.237-.531h-.656v3.625z"
			/>
		</symbol>
		<!-- #endregion Utils -->
	</defs>
</svg>
