import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

import { SLIDE } from '@bp/shared/animations';

@Component({
	selector: 'bp-toggable-card',
	templateUrl: './toggable-card.component.html',
	styleUrls: [ './toggable-card.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
})
export class ToggableCardComponent {

	@HostBinding('class.open')
	open = false;

}
