import { Moment } from 'moment';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bp-publication-date[date]',
	templateUrl: './publication-date.component.html',
	styleUrls: [ './publication-date.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		class: 'mat-small',
	},
})
export class PublicationDateComponent {

	@Input() date!: Moment | null;

}
