<mat-toolbar>
	<a mat-button class="logo-btn" color="primary" routerLink="/">
		<bp-svg-icon name="logo-short-text"></bp-svg-icon>
	</a>

	<div class="ml-2 middle-section">
		<a mat-button routerLink="/product" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
			Product
		</a>

		<a
			mat-button
			routerLink="/bridger-retry"
			class="retry"
			routerLinkActive="active"
			[routerLinkActiveOptions]="{ exact: true }"
		>
			<span>Retry™</span>
		</a>

		<a mat-button routerLink="/connections" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
			Connections
		</a>

		<a mat-button routerLink="/pricing" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
			Pricing
		</a>

		<bp-company-submenu dropdownMode></bp-company-submenu>

		<a mat-button routerLink="/learn" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
			Learn
		</a>
	</div>

	<div class="right-section ml-auto">
		<bp-wizard-link
			classList="get-started-btn promo-flat-button promo-sm-button mr-4"
			bpGoogleTagOnClick="promo_header_cta_click"
		>
			Start for Free
		</bp-wizard-link>

		<a class="link login-btn" href="{{ environment.merchantAdminUrl }}" bpTargetBlank> Login </a>

		<bp-burger-btn
			class="menu-btn bp-icon-button-lg"
			[showCross]="(mobileMenuDialog$ | async) !== undefined"
			(click)="mobileMenuDialog$.value ? mobileMenuDialog$.value.close() : openMobileMenu()"
		>
		</bp-burger-btn>
	</div>
</mat-toolbar>
