import { Subject } from 'rxjs';
import { AnimationDirection, AnimationItem } from 'lottie-web-light';

import type { IDestroyable } from '@bp/shared/models/common';

type BMEnterFrameEvent = {
	currentTime: number;
	direction: AnimationDirection;
	totalTime: number;
	type: 'enterFrame';
};

export abstract class BaseAnimationStrategy implements IDestroyable {

	protected readonly _destroyed$ = new Subject<void>();

	destroyed$ = this._destroyed$.asObservable();

	private readonly _onFrameChange$ = new Subject<BMEnterFrameEvent>();

	onFrameChange$ = this._onFrameChange$.pipe();

	apply($host: HTMLElement, animationItem: AnimationItem): void {
		animationItem.addEventListener('enterFrame', payload => void this._onFrameChange$.next(payload));
	}

	detach(): void {
		this._destroyed$.next();
	}

}
