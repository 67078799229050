<h1 *ngIf="headliner; else titleTpl" class="title mat-headline">{{ header }}</h1>

<ng-template #titleTpl>
	<div class="title mat-title">{{ header }}</div>
</ng-template>

<div class="description">{{ description }}</div>

<bp-wizard-link
	*ngIf="btnRouterLink === defaultGetStartedRouterLink; else defaultBtnTpl"
	classList="promo-flat-button"
	[bpGoogleTagOnClick]="callToActionGaEvent"
>
	{{ btnRouterLinkText ? btnRouterLinkText : 'Start for Free' }}
</bp-wizard-link>

<ng-template #defaultBtnTpl>
	<a
		mat-flat-button
		class="promo-flat-button"
		color="primary"
		[routerLink]="btnRouterLink"
		[fragment]="btnRouterLinkFragment"
	>
		{{ btnRouterLinkText ? btnRouterLinkText : 'Start for Free' }}
	</a>
</ng-template>
