import { forOwn, isEmpty, isString } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';

import { SLIDE } from '@bp/shared/animations';
import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import { TelemetryService } from '@bp/shared/services';
import { NumberMaskConfig } from '@bp/shared/features/text-mask';
import { UiExperimentsService } from '@bp/shared/features/analytics';
import { ZohoLeadService } from '@bp/shared/domains/zoho-lead';
import { BridgerPspIndustry } from '@bp/shared/domains/bridger-psps/core';

import type { IZohoLead } from '@bp/firebase-functions';

import { zohoLeadFormIndustryConfig, zohoLeadFormPhoneConfig } from '../../../../models';

@Component({
	selector: 'bp-get-started-form',
	templateUrl: './get-started-form.component.html',
	styleUrls: [ './get-started-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
	],
	animations: [ SLIDE ],
})
export class GetStartedFormComponent extends Destroyable implements OnInit {

	@Input() buttonText = 'Send';

	phoneMask = new NumberMaskConfig({
		prefix: '+',
		includeThousandsSeparator: false,
	});

	form!: UntypedFormGroup;

	get formValue() {
		return <Required<Pick<IZohoLead, keyof typeof zohoLeadFormIndustryConfig | keyof typeof zohoLeadFormPhoneConfig>>> this.form.value;
	}

	pspIndustries = BridgerPspIndustry.getList();

	submitting$ = new BehaviorSubject(false);

	error$ = new BehaviorSubject(false);

	constructor(
		public uiExperimentsService: UiExperimentsService,
		protected readonly _formBuilder: UntypedFormBuilder,
		private readonly _telemetry: TelemetryService,
		private readonly _activatedRoute: ActivatedRoute,
		private readonly _router: Router,
		private readonly _zohoLeadService: ZohoLeadService,
	) {
		super();
	}

	ngOnInit(): void {
		this._rebuildFormOnFieldsExperimentChange();

		this._onRouteEmailChangeUpdateControl();
	}

	protected _rebuildFormOnFieldsExperimentChange(): void {
		this.uiExperimentsService.showPhoneInputInsteadIndustryInput$
			.pipe(takeUntilDestroyed(this))
			.subscribe(isActive => (this.form = this._formBuilder.group(
				isActive ? zohoLeadFormPhoneConfig : zohoLeadFormIndustryConfig,
			)));
	}

	private _onRouteEmailChangeUpdateControl(): void {
		this._activatedRoute.params
			.pipe(takeUntilDestroyed(this))
			.subscribe(({ email }) => {
				if (isEmpty(email) || !isString(email))
					return;

				this.form.controls['email'].setValue(email);
			});
	}

	submit(): void {
		this._markControlsAsDirtyAndTouched();

		if (this.form.invalid || this.submitting$.value)
			return;

		this._setFormIntoSubmittingState();

		void this._saveLeadAndRedirectToThankYou();
	}

	private _setFormIntoSubmittingState(): void {
		this.form.disable();

		this.submitting$.next(true);

		this.error$.next(false);
	}

	private _saveLeadAndRedirectToThankYou(): void {
		try {
			const lead = this._buildZohoLeadDto();
			const isPartnerLead = this._activatedRoute.snapshot.params['type'] === 'partner';

			this._zohoLeadService.saveLead(lead, isPartnerLead ? 'partner' : 'client');

			this._redirectToThankYou(lead);
		} catch (error: unknown) {
			this._handleSaveError(error);
		}
	}

	private _redirectToThankYou(lead: IZohoLead): void {
		void this._router.navigate([{
			outlets: {
				primary: [ 'thank-you', 'lead', { leadEmail: lead.email }],
				modal: null,
			},
		}]);
	}

	private _markControlsAsDirtyAndTouched(): void {
		forOwn(this.form.controls, control => {
			control.markAsTouched();

			control.markAsDirty();

			control.updateValueAndValidity();
		});
	}

	private _handleSaveError(error: unknown): void {
		this._telemetry.captureError(error);

		this.form.enable();

		this.error$.next(true);

		this.submitting$.next(false);
	}

	protected _buildZohoLeadDto(): IZohoLead {
		return {
			name: this.formValue.name,
			email: this.formValue.email.toLowerCase(),
			companyWebsite: this.formValue.companyWebsite.toLowerCase(),
			...(this.formValue.industry ? { industry: this.formValue.industry } : {}),
			...(this.formValue.phone ? { phone: `+${ this.formValue.phone }` } : {}),
		};
	}

}
