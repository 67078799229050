import type { MetaDefinition } from '@angular/platform-browser';

import { Default, MapFromDTO } from '@bp/shared/models/metadata';

import { Metatags } from './metatags';

export class TwitterMetatags extends Metatags {

	/**
	 * https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started
	 * This affects the image preview on some platforms other than twitter
	 */
	@Default('summary_large_image')
	card?: 'player' | 'summary_large_image' | 'summary';

	/**
	 * @username for the website used in the card footer.
	 */
	@MapFromDTO()
	site?: string;

	/**
	 * 	@username for the content creator / author.
	 */
	@MapFromDTO()
	creator?: string;

	readonly metatagsSchema = 'twitter';

	getMetaDefinitions(): MetaDefinition[] {
		const definitions: MetaDefinition[] = [];

		if (this.card) {
			definitions.push({
				property: `${ this.metatagsSchema }:card`,
				content: this.card,
			});
		}

		if (this.site) {
			definitions.push({
				property: `${ this.metatagsSchema }:site`,
				content: this.site,
			});
		}

		if (this.creator) {
			definitions.push({
				property: `${ this.metatagsSchema }:creator`,
				content: this.creator,
			});
		}

		return definitions;
	}

}
