import { Injectable } from '@angular/core';

import { IntercomService, TelemetryService } from '@bp/shared/services';
import { GoogleTagService } from '@bp/shared/features/analytics';
import { Dictionary } from '@bp/shared/typings';

import { IZohoLead } from '@bp/firebase-functions';

type LeadType = 'client' | 'partner';

@Injectable({ providedIn: 'root' })
export class ZohoLeadService {

	private readonly _hasGeneratedLead: Dictionary<boolean> = {};

	constructor(
		private readonly _intercomService: IntercomService,
		private readonly _telemetry: TelemetryService,
		private readonly _googleTagService: GoogleTagService,
	) {

	}

	saveLead(lead: IZohoLead, type: LeadType = 'client'): void {
		try {
			if (!lead.email)
				return;

			const isPartnerLead = type === 'partner';

			void this._intercomService.update(<Dictionary<any>>lead);

			if (isPartnerLead)
				this._dispatchGoogleTagPartnerLeadEvent(lead);
			else
				this._dispatchGoogleTagLeadEvents(lead);
		} catch (error: unknown) {
			this._telemetry.captureError(error);

			throw error;
		}
	}

	private _dispatchGoogleTagPartnerLeadEvent(lead: IZohoLead): void {
		this._googleTagService.setGlobalVariables({ userEmail: lead.email });

		this._googleTagService.dispatchEvent('new_psp_partner', { lead });
	}

	private _dispatchGoogleTagLeadEvents(lead: IZohoLead): void {
		this._googleTagService.setGlobalVariables({ userEmail: lead.email });

		if (this._hasGeneratedLead[lead.email])
			this._googleTagService.dispatchEvent('lead_updated', { lead });
		else
			this._googleTagService.dispatchEvent('generate_lead', { lead });

		this._hasGeneratedLead[lead.email] = true;
	}

}
