import { filter, first, map, pairwise, startWith, switchMap } from 'rxjs/operators';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { firstValueFrom } from 'rxjs';

import { CommonModule, ViewportScroller } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationRef, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import type { Event } from '@angular/router';
import { ActivatedRouteSnapshot, ActivationEnd, PRIMARY_OUTLET, Router, RouteReuseStrategy, Scroll } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedFeaturesValidationModule } from '@bp/shared/features/validation';
import { SharedPipesModule } from '@bp/shared/pipes';
import {
	FirebaseAppConfig, PLATFORM, FIREBASE_APP_CONFIG, SharedServicesModule, TelemetryService, FirebaseService, MockedBackendState,
	MockFirebaseService
} from '@bp/shared/services';
import { environment } from '@bp/shared/environments';
import { fromWaitUntilZoneXhrMacrotasksQueueEmpty } from '@bp/shared/rxjs';
import { Platform } from '@bp/shared/typings';
import { ensureType } from '@bp/shared/utilities';
import { SharedFeaturesAnalyticsModule } from '@bp/shared/features/analytics';

import { HomeModule } from '@bp/promo/sections/home';

import { AppRoutingModule } from './app-routing.module';
import { AppStartupService } from './app-startup.service';
import { CoreModule, ErrorsModule, RootComponent, PrimaryRouteAnimationObserverService } from './core';
import { ConfigurableRouteReuseStrategy } from './core/routing';

TelemetryService.log('App module execution begun');

type EnhancedScrollEvent = Scroll & {
	navigatedToSameComponent: boolean;
	snapshot: ActivatedRouteSnapshot | undefined;
};

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		BrowserAnimationsModule,

		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.isRemoteServer,
			registrationStrategy: 'registerWhenStable:5000',
		}),

		SharedPipesModule.forRoot(),
		SharedComponentsCoreModule.forRoot(),
		SharedFeaturesValidationModule.forRoot(),
		SharedServicesModule.forRoot(),
		SharedFeaturesAnalyticsModule.forRoot(),

		AppRoutingModule,
		HomeModule,
		CoreModule,

		ErrorsModule, // Should be the last module with routes to properly catch all notfound routes
		ScullyLibModule.forRoot({ useTransferState: true, alwaysMonitor: true }),
	],
	providers: [
		{
			provide: FIREBASE_APP_CONFIG,
			useValue: <FirebaseAppConfig> {
				appId: '1:977741303368:web:5efc568445f73b34',
			},
		},
		{
			provide: RouteReuseStrategy,
			useClass: ConfigurableRouteReuseStrategy,
		},
		{
			provide: PLATFORM,
			useValue: ensureType<Platform>('promo'),
		},
		{
			provide: FirebaseService,
			useClass: MockedBackendState.isActive ? MockFirebaseService : FirebaseService,
		},
	],
	bootstrap: [ RootComponent ],
})
export class AppModule {

	constructor(
		private readonly _appStartupService: AppStartupService,
		private readonly _app: ApplicationRef,
		private readonly _router: Router,
		private readonly _viewportScroller: ViewportScroller,
		private readonly _routeAnimationObserver: PrimaryRouteAnimationObserverService,
	) {
		void this._whenAppIsStableInitStartupLogic();

		this._scrollOnRouterNavigation();
	}

	private async _whenAppIsStableInitStartupLogic(): Promise<void> {
		await firstValueFrom(this._app.isStable);

		this._appStartupService.init();
	}

	/**
	 * Angular scrollPositionRestoration doesn't respect async loaded data in the project.
	 * Therefore, we need to wait until the page fully loaded before restoring scroll position.
	 * Scroll Restoration solution is kind of https://github.com/angular/angular/issues/24547#issuecomment-941827675
	 */
	private _scrollOnRouterNavigation(): void {

		/**
		 * In case of `auto` strategy, the browser restores scroll position
		 * itself before NavigationStart event on back navigation.
		 * Therefore, the scroll position of current page saves incorrectly,
		 * because it happens on NavigationStart router event
		 * https://github.com/angular/angular/blob/a92a89b0eb127a59d7e071502b5850e57618ec2d/packages/router/src/router_scroller.ts#L54
		 * This causes to incorrect scroll restoration on forward navigation.
		 *
		 * With `manual` strategy browser does nothing, and so ng router can remember position correctly.
		 */
		window.history.scrollRestoration = 'manual';

		const primaryOutletActivationEndEvent$ = this._router.events.pipe(
			filter((routeEvent: Event): routeEvent is ActivationEnd => routeEvent instanceof ActivationEnd
				&& !!routeEvent.snapshot.component
				&& routeEvent.snapshot.outlet === PRIMARY_OUTLET),
		);

		const scrollEvent$ = this._router.events.pipe(
			filter((routeEvent: Event): routeEvent is Scroll => routeEvent instanceof Scroll),
		);

		primaryOutletActivationEndEvent$.pipe(
			startWith(null),
			pairwise(),
			switchMap(([ previousActivationEnd, activationEnd ]) => scrollEvent$.pipe(
				map(scrollEvent => ({
					...scrollEvent,
					navigatedToSameComponent: previousActivationEnd?.snapshot.component === activationEnd!.snapshot.component,
					snapshot: activationEnd?.snapshot,
				})),
				first(),
			)),
			switchMap(primaryOutletScrollEvent => {
				if (this._shouldScrollToTop(primaryOutletScrollEvent))
					this._viewportScroller.scrollToPosition([ 0, 0 ]);

				return this._routeAnimationObserver.isAnimationDone$.pipe(
					switchMap(() => fromWaitUntilZoneXhrMacrotasksQueueEmpty()),
					map(() => primaryOutletScrollEvent),
				);
			}),
		).subscribe(primaryOutletScrollEvent => {
			if (primaryOutletScrollEvent.position) {
				// Backward navigation
				window.scroll({
					left: primaryOutletScrollEvent.position[0],
					top: primaryOutletScrollEvent.position[1],
					behavior: 'smooth',
				});
			} else if (primaryOutletScrollEvent.anchor) {
				document.querySelector(`#${ primaryOutletScrollEvent.anchor }`)
					?.scrollIntoView({ behavior: 'smooth' });
			}
		});
	}

	private _shouldScrollToTop(enhancedScrollEvent: EnhancedScrollEvent): boolean {
		if (!enhancedScrollEvent.navigatedToSameComponent)
			return true;

		if (enhancedScrollEvent.anchor)
			return false;

		return !!enhancedScrollEvent.snapshot?.data['doNotReuseComponent'];
	}

}
