import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Dictionary } from '@bp/shared/typings';

import { WizardConnectService } from '../../services';

@Component({
	selector: 'bp-wizard-link',
	templateUrl: './wizard-link.component.html',
	styleUrls: [ './wizard-link.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardLinkComponent {

	@Input()
	set params(params: Dictionary<string> | undefined) {
		this.wizardUrl = this._wizardConnectService.getWizardUrl(params);
	}

	@Input() classList?: string;

	wizardUrl = this._wizardConnectService.getWizardUrl();

	constructor(private readonly _wizardConnectService: WizardConnectService) { }

}
