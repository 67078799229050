import { isNil } from 'lodash-es';

import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { RouterService } from '@bp/shared/services';
import { UrlHelper } from '@bp/shared/utilities';
import { DTO } from '@bp/shared/models/metadata';

import type { RouteDataMetatags } from '../models';
import { RouteMetatags } from '../models';

@Injectable({
	providedIn: 'root',
})
export class MetatagsService {

	private _defaultMetatags!: RouteMetatags;

	private _inited = false;

	private _host!: string;

	constructor(
		private readonly _router: RouterService,
		private readonly _route: ActivatedRoute,
		private readonly _ngMeta: Meta,
	) { }

	init(host: string, defaultMetatags: RouteMetatags): void {
		if (this._inited)
			return;

		this._host = host;

		this._defaultMetatags = defaultMetatags;

		this._onNavigationEndSetMetatagsDeclaredInRoute();

		this._inited = true;
	}

	setMetatags(metatags: DTO<RouteMetatags> | RouteMetatags): void {
		this._updateMetatags(new RouteMetatags({
			...this._defaultMetatags,
			...metatags,
		}));
	}

	private _updateMetatags(metatags: RouteMetatags): void {
		this._updateHtmlMetatags(this._addHostToUrlsIfNonePresent(metatags));
	}

	private _addHostToUrlsIfNonePresent(metatags: RouteMetatags): RouteMetatags {
		return new RouteMetatags({
			...metatags,
			url: this._prependDefaultHostNameToUrlIfNone(metatags.url),
			image: this._prependDefaultHostNameToUrlIfNone(metatags.image),
		});
	}

	private _prependDefaultHostNameToUrlIfNone(url?: string | null): string | null {
		if (!url)
			return null;

		return url.startsWith('https://') ? url : `https://${ this._host }${ url }`;
	}

	private _onNavigationEndSetMetatagsDeclaredInRoute(): void {
		this._router.navigationEnd$
			.subscribe(({ url }) => void this._updateMetatags(
				this._mergeLastPrimaryRouteMetatagsWithDefault(url),
			));
	}

	private _mergeLastPrimaryRouteMetatagsWithDefault(url: string): RouteMetatags {
		const lastPrimaryRouteData = <RouteDataMetatags> UrlHelper.getLastPrimaryRoute(this._route).snapshot.data;

		return new RouteMetatags({
			url,
			...this._defaultMetatags,
			title: isNil(lastPrimaryRouteData.title) ? this._defaultMetatags.title : lastPrimaryRouteData.title,
			...lastPrimaryRouteData.metatags,
		});
	}

	private _updateHtmlMetatags(metatags: RouteMetatags): void {
		this._removeOldHtmlMetatags(metatags);

		this._ngMeta.addTags(metatags.getMetaDefinitions());
	}

	private _removeOldHtmlMetatags(metatags: RouteMetatags): void {
		metatags
			.getMetatagSchemas()
			.flatMap(schema => this._ngMeta.getTags(`property^='${ schema }:'`))
			.forEach(tag => void this._ngMeta.removeTagElement(tag));

		metatags
			.getSeoMetatagsNames()
			.forEach(name => void this._ngMeta.removeTag(`name="${ name }"`));
	}

}
