import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

import { ReactiveComponentModule } from '@ngrx/component';

import { SharedFeaturesCarouselModule } from '@bp/shared/features/carousel';
import { SharedFeaturesMediaModule } from '@bp/shared/features/media';
import { SharedFeaturesAnalyticsModule } from '@bp/shared/features/analytics';

import { SharedModule } from '@bp/promo/shared';

import { HomeRoutingModule } from './home-routing.module';
import { AboveFoldComponent, SupportComponent, BuildPaymentFlowsComponent, CompaniesComponent, CompaniesTilesComponent, ContactsWithConcernedPartiesComponent, HomePageComponent, PaymentPlatformToFitComponent, PluginPaymentsComponent, TestimonialsComponent, NextGenOperationsComponent, AboveFoldSecureComponent, AboveFoldVideoComponent } from './pages';
import { CompaniesTilesPhoneComponent } from './pages/home-page/components/companies/components/companies-tiles-phone';
import { ForDevsPricingComponent } from './pages/home-page/components/for-devs-pricing';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		MatExpansionModule,
		ReactiveComponentModule,

		SharedFeaturesCarouselModule,

		HomeRoutingModule,
		SharedFeaturesMediaModule,
		SharedFeaturesAnalyticsModule,
	],
	declarations: [
		HomePageComponent,
		AboveFoldComponent,
		CompaniesTilesComponent,
		CompaniesTilesPhoneComponent,
		CompaniesComponent,
		ContactsWithConcernedPartiesComponent,
		PaymentPlatformToFitComponent,
		PluginPaymentsComponent,
		BuildPaymentFlowsComponent,
		SupportComponent,
		NextGenOperationsComponent,
		TestimonialsComponent,
		ForDevsPricingComponent,
		AboveFoldSecureComponent,
		AboveFoldVideoComponent,
	],
})
export class HomeModule {
}
