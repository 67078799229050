import { combineLatest } from 'rxjs';

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SLIDE } from '@bp/shared/animations';
import { ModalService, ModalHostComponent } from '@bp/shared/features/modal';
import { takeUntilDestroyed } from '@bp/shared/models/common';
import { takeFirstPresent, takeFirstTruthy } from '@bp/shared/rxjs';
import { MediaService } from '@bp/shared/features/media';
import { EnvironmentService } from '@bp/shared/services';

export const MOBILE_MENU_DIALOG_ID = 'mobile-popup-menu';

@Component({
	selector: 'bp-modal-menu',
	templateUrl: './modal-menu.component.html',
	styleUrls: [ './modal-menu.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
})
export class ModalMenuComponent extends ModalHostComponent {

	override id = MOBILE_MENU_DIALOG_ID;

	showCompaniesMenu = false;

	override panelClass = [ 'modal-menu-pane' ];

	constructor(
		public environment: EnvironmentService,
		private readonly _media: MediaService,
		modalService: ModalService,
	) {
		super(modalService);

		this._closeModalMenuOnLaptopBreakpoint();
	}

	private _closeModalMenuOnLaptopBreakpoint(): void {
		combineLatest([
			this._media.greaterThan$('Tablet')
				.pipe(takeFirstTruthy),
			this._modalService
				.getOpenDialogById$(MOBILE_MENU_DIALOG_ID)
				.pipe(takeFirstPresent),
		])
			.pipe(takeUntilDestroyed(this))
			.subscribe(([ , dialog ]) => void dialog.close());
	}
}
