/* eslint-disable @typescript-eslint/naming-convention */
export const CRYPTO_CURRENCY_CODES_AND_NAMES = {
	ADA: [ 'Cardano', '₳' ],
	BCH: [ 'Bitcoin Cash' ],
	BTC: [ 'Bitcoin', '₿' ],
	DASH: [ 'DASH' ],
	DOGE: [ 'Dogecoin', 'Ɖ' ],
	ETH: [ 'Ethereum', 'Ξ' ],
	GUSD: [ 'Gemini Dollar' ],
	IOST: [ 'IOSToken' ],
	LTC: [ 'Litecoin', 'Ł' ],
	NEM: [ 'NEM' ],
	NEO: [ 'NEO' ],
	OMG: [ 'OmiseGO' ],
	PAX: [ 'Paxos Standard Token' ],
	TUSD: [ 'TrueUSD' ],
	USDC: [ 'USD Coin' ],
	USDT: [ 'Tether', '₮' ],
	'USDT-TRC20': [ 'Tether (Tron)', '₮' ],
	'USDT-ERC20': [ 'Tether (Ethereum)', '₮' ],
	'USDT-BEP20': [ 'Tether (Binance)', '₮' ],
	VIU: [ 'Viuly' ],
	TRX: [ 'TRX' ],
	XMR: [ 'Monero' ],
	XRP: [ 'Ripple' ],
};
