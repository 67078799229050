import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

import { SharedFeaturesTouchModule } from '@bp/shared/features/touch';
import { SharedPipesModule } from '@bp/shared/pipes';

import { CarouselComponent } from './carousel.component';

@NgModule({
	imports: [
		CommonModule,
		DragDropModule,
		MatRippleModule,

		SharedFeaturesTouchModule,
		SharedPipesModule,
	],
	declarations: [ CarouselComponent ],
	exports: [ CarouselComponent ],
})
export class SharedFeaturesCarouselModule { }
