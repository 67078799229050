import { isString } from 'lodash-es';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { LocationStrategy } from '@angular/common';
import type { OnChanges } from '@angular/core';
import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import type { UrlSegmentGroup, UrlTree } from '@angular/router';
import {
	ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, QueryParamsHandling, Router, RouterLinkWithHref
} from '@angular/router';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';

import { RouterLinkInput } from './router-link-input-type';

/**
 * We need our own implementation of RouterLink directive because the angular's directive
 * doesn't remove current outlets presented in url from generated links
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'a[routerLinkNoOutlets]',
	providers: [
		{
			provide: RouterLinkWithHref,
			useExisting: RouterLinkNoOutletsWithHrefDirective,
		},
	],
})
export class RouterLinkNoOutletsWithHrefDirective extends Destroyable implements OnChanges {

	@Input()
	set routerLinkNoOutlets(commands: RouterLinkInput) {
		this._commands = commands === null
			? []
			: (Array.isArray(commands) ? commands : [ commands ]);
	}

	@Input() routerLinkActive?: string;

	@Input() routerLinkActiveOptions: { exact: boolean } = { exact: false };

	@HostBinding('attr.target') @Input() target!: string;

	@Input() queryParams!: Record<string, any>;

	@Input() fragment!: string;

	@Input() queryParamsHandling!: QueryParamsHandling;

	@Input() preserveFragment!: boolean | '';

	@Input() skipLocationChange!: boolean | '';

	@Input() replaceUrlOnLocationHistory!: boolean | '';

	@Input() state?: Record<string, any>;

	// the url displayed on the anchor element.
	@HostBinding() href!: string;

	// eslint-disable-next-line rxjs/finnish
	onChanges = new Subject<RouterLinkNoOutletsWithHrefDirective>();

	private _commands: any[] = [];

	constructor(
		private readonly _router: Router,
		private readonly _route: ActivatedRoute,
		private readonly _locationStrategy: LocationStrategy,
	) {
		super();

		_router.events
			.pipe(
				filter(routeEvent => routeEvent instanceof NavigationEnd),
				takeUntilDestroyed(this),
			)
			.subscribe(() => void this._updateHref());
	}

	ngOnChanges() {

		/*
		 * This is subscribed to by `RouterLinkActive` so that it knows to update when there are changes
		 * to the RouterLinks it's tracking.
		 */
		this.onChanges.next(this);

		this._updateHref();
	}

	@HostListener('click', [ '$event.button', '$event.ctrlKey', '$event.metaKey', '$event.shiftKey' ])
	onClick(button: number, ctrlKey: boolean, metaKey: boolean, shiftKey: boolean): boolean {
		if (button !== 0 || ctrlKey || metaKey || shiftKey)
			return true;

		if (isString(this.target) && this.target !== '_self')
			return true;

		void this._router.navigateByUrl(this.urlTree, {
			skipLocationChange: coerceBooleanProperty(this.skipLocationChange),
			replaceUrl: coerceBooleanProperty(this.replaceUrlOnLocationHistory),
			state: this.state,
		});

		return false;
	}

	private _updateHref(): void {
		this.href = this._buildHref();
	}

	private _buildHref(): string {
		return this._locationStrategy.prepareExternalUrl(this._router.serializeUrl(this.urlTree));
	}

	get urlTree(): UrlTree {
		let tree = this._router.createUrlTree([{ outlets: { [PRIMARY_OUTLET]: this._commands } }], {
			relativeTo: this._route.root,
			queryParams: this.queryParams,
			fragment: this.fragment,
			queryParamsHandling: this.queryParamsHandling,
			preserveFragment: coerceBooleanProperty(this.preserveFragment),
		});

		/*
		 * clone tree, cause createUrlTree creates shared tree with the router and
		 * we don't want to affect inner router state
		 */
		tree = this._router.parseUrl(tree.toString());

		this._removeNonPrimaryOutlets(tree.root);

		return tree;
	}

	private _removeNonPrimaryOutlets({ children }: UrlSegmentGroup) {
		for (const key of Object.keys(children)) {
			if (key === PRIMARY_OUTLET)
				this._removeNonPrimaryOutlets(children[key]);
			else
				// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
				delete children[key];
		}
	}

}
