<div class="wrapper">
	<h2 class="headliner uppercase"><span class="primary green-dot">24/7 support</span></h2>

	<p>
		We know how important your business is, so you can reach out to us anytime. Just click on the Intercom button
		and our payment experts will be there for you.
	</p>

	<a
		*bpIfGreaterThan="'Tablet'"
		mat-flat-button
		class="promo-flat-button lets-talk-btn"
		color="primary"
		bpGoogleTagOnClick="promo_support_cta_click"
		(click)="intercomService.show()"
	>
		Let's Talk
	</a>

	<div #media class="media">
		<bp-picture src="/assets/home/support/support-guys" responsive></bp-picture>

		<bp-lottie-scene
			sceneName="home-support-text-bubbles"
			[animationStrategy]="manualAnimationStrategy"
		></bp-lottie-scene>

		<bp-lottie-scene *bpIfGreaterThan="'PhoneLg'" sceneName="home-support-24-7" looped></bp-lottie-scene>
	</div>
</div>
