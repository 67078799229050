<bp-above-fold></bp-above-fold>

<bp-companies></bp-companies>

<div class="dotted-bg-wrapper">
	<div class="dotted-bg"></div>

	<bp-payment-platform-to-fit></bp-payment-platform-to-fit>

	<bp-plugin-payments></bp-plugin-payments>

	<bp-build-payment-flows></bp-build-payment-flows>
</div>

<bp-support></bp-support>

<bp-next-gen-operations></bp-next-gen-operations>

<bp-testimonials></bp-testimonials>

<bp-work-smarter
	[headliner]="(mediaService.lessThan$('Tablet') | async) ? 'PAYMENT OPERATIONS' : 'SCALE THROUGH PAYMENTS'"
></bp-work-smarter>

<!--<bp-contacts-with-concerned-parties></bp-contacts-with-concerned-parties>-->

<bp-for-devs-pricing></bp-for-devs-pricing>
