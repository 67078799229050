import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PlayWhenPartiallyInViewportAnimationStrategy } from '@bp/promo/shared';

@Component({
	selector: 'bp-plugin-payments',
	templateUrl: './plugin-payments.component.html',
	styleUrls: [ './plugin-payments.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PluginPaymentsComponent {

	animationStrategy = new PlayWhenPartiallyInViewportAnimationStrategy(0.6);

}
