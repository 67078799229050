import { QuicklinkModule } from 'ngx-quicklink';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesModalModule } from '@bp/shared/features/modal';
import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedFeaturesAnalyticsModule } from '@bp/shared/features/analytics';

import { SharedModule } from '@bp/promo/shared';

import {
	CompanySubmenuComponent, CookiesUsageConsentPopupComponent, FooterComponent, HeaderComponent, ModalMenuComponent,
	ProductSubmenuComponent, RootComponent, SubmenuItemComponent, SvgIconsDefinitionsComponent, WeAreHiringBadgeComponent
} from './components';

export const COMPONENTS = [
	SvgIconsDefinitionsComponent,
	CookiesUsageConsentPopupComponent,
	CompanySubmenuComponent,
	ModalMenuComponent,
	ProductSubmenuComponent,
	RootComponent,
	HeaderComponent,
	FooterComponent,
	WeAreHiringBadgeComponent,
	SubmenuItemComponent,
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		MatIconModule,
		MatButtonModule,
		MatMenuModule,
		MatToolbarModule,
		MatDialogModule,
		QuicklinkModule,

		SharedModule,
		SharedDirectivesModule,
		SharedComponentsCoreModule,
		SharedFeaturesSvgIconsModule,
		SharedFeaturesModalModule,
		SharedFeaturesAnalyticsModule,
		SharedModule,
	],
	declarations: COMPONENTS,
	exports: COMPONENTS,
})
export class CoreModule { }
