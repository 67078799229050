import { BehaviorSubject } from 'rxjs';

import { ChangeDetectionStrategy, Component, DoCheck, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { GoogleOptimizeService } from '@bp/shared/features/analytics';

import { FADE_ROUTE_HIT_ANIMATIONS } from '@bp/promo/shared';

import { PrimaryRouteAnimationObserverService } from '../../services';

@Component({
	selector: 'bp-root',
	templateUrl: './root.component.html',
	styleUrls: [ './root.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_ROUTE_HIT_ANIMATIONS ],
})
export class RootComponent implements DoCheck {
	@ViewChild('outlet', { static: true, read: RouterOutlet }) outlet!: RouterOutlet;

	private readonly _isLanding$ = new BehaviorSubject(false);

	isLanding$ = this._isLanding$.asObservable();

	shouldAnimateOutlet = false;

	constructor(
		public routeAnimationObserver: PrimaryRouteAnimationObserverService,
		private readonly _googleOptimize: GoogleOptimizeService,
	) {
	}

	onActivate(): void {
		this.shouldAnimateOutlet = true;

		/**
		 * Outlet animation usually starts after all router events are triggered.
		 * So make animation pending a bit before (on ActivationEnd event), so it is becomes usable
		 * on router events subscriptions.
		 */
		this.routeAnimationObserver.started();

		this._isLanding$.next(!!this.outlet.activatedRoute.snapshot.data['landing']);
	}

	ngDoCheck(): void {
		this._googleOptimize.activate();
	}
}
