import { fromEvent } from 'rxjs';

import { Directive, ElementRef, OnInit, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';

@Directive({ selector: '[bpTrimOnPaste]' })
export class TrimOnPasteDirective extends Destroyable implements OnInit {

	constructor(
		private readonly _elementRef: ElementRef,
		@Optional() private readonly _ngControl: NgControl | null,
	) {
		super();
	}

	ngOnInit(): void {
		this._assertControlExisted();

		this._trimOnValueChange();
	}

	private _assertControlExisted(): void {
		if (!this._ngControl || !this._ngControl.control)
			throw new Error('The bpTrimOnPaste directive should be used with one of ngModel, formControl or formControlName');
	}

	private _trimOnValueChange(): void {
		fromEvent<ClipboardEvent>(this._elementRef.nativeElement, 'paste')
			.pipe(takeUntilDestroyed(this))
			.subscribe((event: ClipboardEvent) => {
				event.stopPropagation();

				event.preventDefault();

				this._ngControl!.control!.setValue(event.clipboardData?.getData('text').trim() ?? '');
			});
	}

}
