import { isScullyRunning } from '@scullyio/ng-lib';

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FADE } from '@bp/shared/animations';

import { CookiesUsageConsentService } from '../../services';

@Component({
	selector: 'bp-cookies-usage-consent-popup',
	templateUrl: './cookies-usage-consent-popup.component.html',
	styleUrls: [ './cookies-usage-consent-popup.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class CookiesUsageConsentPopupComponent {

	isScullyRunning = isScullyRunning();

	constructor(public cookiesUsageConsentService: CookiesUsageConsentService) { }

}
