/* eslint-disable @typescript-eslint/promise-function-async */
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { TelemetryService } from '@bp/shared/services';

import { HomePageComponent } from '@bp/promo/sections/home';
import { GetStartedModalComponent } from '@bp/promo/shared';

import { ModalMenuComponent } from './core';

// App routing module is for lazy routes and modals

const routes: Routes = [
	{
		path: 'menu',
		component: ModalMenuComponent,
		outlet: 'modal',
	},
	{
		path: 'get-started',
		component: GetStartedModalComponent,
		outlet: 'modal',
	},
	{
		path: '',
		component: HomePageComponent,
	},
	{
		path: 'about-us',
		loadChildren: () => import('@bp/promo/sections/about')
			.then(m => m.AboutModule),
	},
	{
		path: 'book-demo-calendar',
		loadChildren: () => import('@bp/promo/sections/book-demo-calendar')
			.then(m => m.BookDemoCalendarModule),
	},
	{
		path: 'pricing',
		loadChildren: () => import('@bp/promo/sections/pricing')
			.then(m => m.PricingModule),
	},
	{
		path: 'product',
		loadChildren: () => import('@bp/promo/sections/product')
			.then(m => m.ProductModule),
	},
	{
		path: 'usecases',
		loadChildren: () => import('@bp/promo/sections/usecases')
			.then(m => m.UsecasesModule),
	},
	{
		path: 'newsroom',
		loadChildren: () => import('@bp/promo/sections/newsroom')
			.then(m => m.NewsroomModule),
	},
	{
		path: 'bridger-retry',
		loadChildren: () => import('@bp/promo/sections/retry')
			.then(m => m.RetryModule),
	},
	{
		path: 'cookies-policy',
		loadChildren: () => import('@bp/promo/sections/cookies-policy')
			.then(m => m.CookiesPolicyModule),
	},
	{
		path: 'connections',
		loadChildren: () => import('@bp/promo/sections/psp-integrations')
			.then(m => m.PspIntegrationsModule),
	},
	{
		path: 'payment-orchestration',
		loadChildren: () => import('@bp/promo/sections/payment-orchestration')
			.then(m => m.PaymentOrchestrationModule),
	},
	{
		path: 'privacy-policy',
		loadChildren: () => import('@bp/promo/sections/privacy-policy')
			.then(m => m.PrivacyPolicyModule),
	},
	{
		path: 'learn',
		loadChildren: () => import('@bp/promo/sections/bridger-events')
			.then(m => m.BridgerEventsModule),
	},
	{
		path: 'prohibited-businesses',
		loadChildren: () => import('@bp/promo/sections/prohibited-businesses')
			.then(m => m.ProhibitedBusinessesModule),
	},
	// {
	// 	path: 'security',
	// 	loadChildren: () => import('@bp/promo/sections/security')
	// 		.then(m => m.SecurityModule),
	// },
	{
		path: 'terms-of-service',
		loadChildren: () => import('@bp/promo/sections/terms-of-service')
			.then(m => m.TermsOfServiceModule),
	},
	{
		path: 'thank-you',
		loadChildren: () => import('@bp/promo/sections/thank-you')
			.then(m => m.ThankYouModule),
	},
	{
		path: 'careers',
		loadChildren: () => import('@bp/promo/sections/careers')
			.then(m => m.CareersModule),
	},
	{
		path: 'landings',
		loadChildren: () => import('@bp/promo/landings')
			.then(m => m.LandingsRoutingModule),
		data: {
			landing: true,
		},
	},
	{
		path: 'subscription-terms',
		loadChildren: () => import('@bp/promo/sections/subscription-terms')
			.then(module => module.SubscriptionTermsModule),
	},
];

@NgModule({
	imports: [
		QuicklinkModule,
		RouterModule.forRoot(routes, {
			enableTracing: false,
			initialNavigation: 'enabledNonBlocking',
			paramsInheritanceStrategy: 'always',
			onSameUrlNavigation: 'reload',
			canceledNavigationResolution: 'replace',
			preloadingStrategy: QuicklinkStrategy,
			errorHandler: TelemetryService.routerErrorHandler,
		}),
	],
	exports: [ RouterModule, QuicklinkModule ],
})
export class AppRoutingModule { }
