import { isString, range } from 'lodash-es';
import type { AnimationItem } from 'lottie-web-light';
import Lottie from 'lottie-web-light';
import type { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import type { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, Output } from '@angular/core';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import { fromViewportIntersection, ZoneService } from '@bp/shared/rxjs';
import { MediaService } from '@bp/shared/features/media';
import { attrBoolValue } from '@bp/shared/utilities';

import type { ILottieAnimationStrategy, LottieAnimationStrategyName } from './models';
import { lottieAnimationStrategyFactory, PlayWhenPartiallyInViewportAnimationStrategy } from './models';

const LOTTIE_SCENES_ASSETS_DIR = '/assets/lottie-scenes';

@Component({
	selector: 'bp-lottie-scene',
	templateUrl: './lottie-scene.component.html',
	styleUrls: [ './lottie-scene.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LottieSceneComponent extends Destroyable implements OnInit, AfterViewInit, OnDestroy {

	@Input()
	@HostBinding('class')
	sceneName!: string;

	@Input() looped: boolean | '' = false;

	@Input() eager: boolean | '' = false;

	@HostBinding('class.no-fade-in')
	@Input() noFadeIn: boolean | '' = false;

	private _animationStrategy: ILottieAnimationStrategy = new PlayWhenPartiallyInViewportAnimationStrategy(0.9);

	@Input() set animationStrategy(strategy: ILottieAnimationStrategy | LottieAnimationStrategyName) {
		this._animationStrategy = isString(strategy) ? lottieAnimationStrategyFactory(strategy) : strategy;
	}

	@Input() supportsWidescreen = false;

	@HostBinding('class.loaded')
	private readonly _loaded$ = new BehaviorSubject(false);

	@Output() readonly loaded$ = this._loaded$.asObservable();

	private readonly _$host = this._hostRef.nativeElement;

	private _animationItem?: AnimationItem;

	constructor(
		private readonly _hostRef: ElementRef<HTMLElement>,
		private readonly _http: HttpClient,
		private readonly _mediaService: MediaService,
		private readonly _cdr: ChangeDetectorRef,
		private readonly _zoneService: ZoneService,
	) {
		super();
	}

	async ngOnInit(): Promise<void> {
		this.looped = attrBoolValue(this.looped);

		this.eager = attrBoolValue(this.eager);

		this.noFadeIn = attrBoolValue(this.noFadeIn);

		this._animationItem = this.eager
			? await this._createLottieAnimation()
			: await this._createLottieAnimationWhenHostIsAboutToEnterViewport();

		this._markAsLoadedOnLottieDOMLoad();
	}

	ngAfterViewInit(): void {
		this._loaded$
			.pipe(
				first(v => v),
				takeUntilDestroyed(this),
			)
			.subscribe(() => void this._animationStrategy.apply(this._$host, this._animationItem!));
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();

		this._animationStrategy.detach();

		this._animationItem?.destroy();
	}

	private _markAsLoadedOnLottieDOMLoad(): void {
		this._animationItem!.addEventListener('DOMLoaded', () => {
			this._loaded$.next(true);

			this._cdr.markForCheck();
		});
	}

	private async _createLottieAnimationWhenHostIsAboutToEnterViewport(): Promise<AnimationItem> {
		await lastValueFrom(this._observeHostIsAboutToEnterViewport()
			.pipe(first(entry => entry.isIntersecting)));

		return this._createLottieAnimation();
	}

	private async _createLottieAnimation(): Promise<AnimationItem> {
		const animationData = await this._loadAnimationDataAccordingToDPR();

		return this._zoneService.runOutsideAngular(() => Lottie.loadAnimation({
			animationData,
			container: this._$host,
			renderer: 'svg',
			loop: !!this.looped,
			autoplay: false,
		}));
	}

	private async _loadAnimationDataAccordingToDPR(): Promise<Record<string, unknown>> {
		const sceneFolderSource = `${ LOTTIE_SCENES_ASSETS_DIR }/${ this.sceneName }`;
		const dataFileSource = `${ sceneFolderSource }/data.json`;
		const imageFolderSource = `${ sceneFolderSource }/images${ this._buildImagesFolderSuffix() }/`;

		const animationDataText = await lastValueFrom(this._http.get(dataFileSource, { responseType: 'text' }));

		return JSON.parse(animationDataText.replace(/images\//ug, imageFolderSource));
	}

	private _observeHostIsAboutToEnterViewport(): Observable<IntersectionObserverEntry> {
		return fromViewportIntersection(
			this._$host,
			{
				rootMargin: '0px 0px 75% 0px',
				threshold: range(0, 1, 0.01),
			},
		);
	}

	private _buildImagesFolderSuffix(): string {
		if (this.supportsWidescreen && this._mediaService.greaterThan('Laptop'))
			return '@4x';

		return this._mediaService.isHighDPR ? '@2x' : '';
	}

}
