import { isString } from 'lodash-es';

import { Injectable } from '@angular/core';

import { ZoneService } from '@bp/shared/rxjs';

import { TouchManager } from './touch-manager';

@Injectable({
	providedIn: 'root',
})
export class TouchBuilder {

	constructor(private readonly _zoneService: ZoneService) { }

	build(elementOrSelector: Element | string): TouchManager | null {
		const $element = isString(elementOrSelector)
			? document.querySelector(elementOrSelector)
			: elementOrSelector;

		return $element && new TouchManager($element, this._zoneService);
	}
}
