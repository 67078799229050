import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import type { RouteDataMetatags } from '@bp/shared/features/metatags';

import { SharedModule } from '@bp/promo/shared';

import { ErrorPageComponent, NotFoundPageComponent } from './pages';

const routes: Routes = [
	{
		path: 'error',
		component: ErrorPageComponent,
		data: <RouteDataMetatags>{
			title: 'Error',
		},
	},
	{
		path: '**',
		component: NotFoundPageComponent,
		data: {
			title: '404 – not found',
		},
	},
];

@NgModule({
	imports: [
		MatIconModule,
		RouterModule.forChild(routes),
		SharedModule,
	],
	declarations: [ ErrorPageComponent, NotFoundPageComponent ],
	exports: [ RouterModule ],
})
export class ErrorsModule { }
