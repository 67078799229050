<bp-pending-animation *ngIf="!article && pending" @fadeIn></bp-pending-animation>

<div *ngIf="article" @fadeIn [@.disabled]="!routerNavigated">
	<ng-content select=".tags"></ng-content>

	<h1 class="uppercase">{{ article.title }}</h1>

	<div *ngIf="article.coverImgUrl || article.youtubeVideoId" bp-scully-content-rendered-beacon class="media">
		<bp-youtube-player
			*ngIf="article.youtubeVideoId as videoId; else coverImgTpl"
			[autoplay]="!isScullyRunning"
			[src]="videoId"
		>
		</bp-youtube-player>

		<ng-template #coverImgTpl>
			<img
				*ngIf="article.coverImgUrl"
				class="cover-img"
				loading="lazy"
				[src]="article.coverImgUrl"
				[alt]="article.title"
			/>
		</ng-template>
	</div>

	<div class="below-cover-img-section">
		<bp-publication-date [date]="article.publicationDate"></bp-publication-date>

		<ng-content select=".below-cover-img-extra-data"></ng-content>

		<div class="socials ml-auto">
			<a
				*ngFor="let social of socials"
				class="bp-icon-button-md"
				mat-icon-button
				bpTargetBlank
				[href]="social | socialShareLink: article.title"
			>
				<bp-svg-icon [name]="social.name"></bp-svg-icon>
			</a>
		</div>
	</div>

	<div class="longread-content-typography" [innerHtml]="content"></div>
</div>
