import { ChangeDetectionStrategy, Component } from '@angular/core';

type Card = {
	icon: string;
	title: string;
	text: string;
	link?: string[];
};

@Component({
	selector: 'bp-next-gen-operations',
	templateUrl: './next-gen-operations.component.html',
	styleUrls: [ './next-gen-operations.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NextGenOperationsComponent {

	cards: Card[] = [
		{
			icon: 'unified-and-agnostic',
			title: 'Unified & Agnostic',
			text: 'Built for any business, works on any platform, connected to over 500 PSPs (and counting...)',
			link: [ '/connections' ],
		},
		{
			icon: 'security-first',
			title: 'Security first',
			text: 'Eliminate the need for PCI with fully PCI DSS Level 1 interfaces served in one API.',
			link: [ '/newsroom/blog/F8i3Qqg6Zi5qKevNLSaA' ],
		},
		{
			icon: 'data-centric',
			title: 'Data-centric',
			text: 'Own your payments data and wave goodbye to multiple back offices and data sets. One reporting engine for all your payment operations.',
			link: [ '/product' ],
		},
	];

}
