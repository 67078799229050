import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'bp-companies-tiles',
	templateUrl: './companies-tiles.component.html',
	styleUrls: [ './companies-tiles.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompaniesTilesComponent {

	assetsDir = '/assets/home/companies';

	logosAssetsDir = '/assets/shared/companies-logos';

	companiesTilesNames = [
		'visa',
		'csmoney',
		'james-allen',
		'magento-icon',
		'woo-commerce',
		'mc-icon',
		'zalora',
		'paybis',
		'btobet',

		'prestashop',
		'wordpress',
		'amex',
		'luxury-escapes-two-lines',
		'gfg',
		'calypsa',
		'qoneco',
		'etihad',
		'eightcap',
	];

}
