import { VideoTestimonialDto } from '../models';

import { LOGOS_ASSETS_DIR } from './paths';

export const VIDEO_TESTIMONIALS: VideoTestimonialDto[] = [
	{
		name: 'Kannan Rajaratnam',
		position: 'Regional Head of Payments, Zalora',
		logo: `${ LOGOS_ASSETS_DIR }/zalora-letter.png`,
		logo2x: `${ LOGOS_ASSETS_DIR }/zalora-letter@2x.png`,
		rate: 5,
		text: `“BridgerPay was able to deliver a lot of customization that Zalora asked for.
				This didn’t just keep us happy, it kept our partners and other payment channels happy as well”.`,
		videoId: 'ee8wqNyZIQk',
		linkText: 'Read the case study',
		routerLink: '/newsroom/Jyg0jmdNvp2xUmIJedJ4',
	},
];
