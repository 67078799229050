import { isScullyRunning } from '@scullyio/ng-lib';
import { fromEvent, merge } from 'rxjs';

import { Injectable, ViewContainerRef } from '@angular/core';

import { MetatagsService } from '@bp/shared/features/metatags';

@Injectable()
export class ScullyPageInvalidWatcherViewService {

	private readonly _$host = <HTMLElement> this._viewContainerRef.element.nativeElement;

	constructor(
		private readonly _viewContainerRef: ViewContainerRef,
		private readonly _metatagsService: MetatagsService,
	) { }

	watch(): void {
		if (isScullyRunning())
			this._onScullyEventCheckContentIsValid();
	}

	private _onScullyEventCheckContentIsValid(): void {
		merge(
			fromEvent(window, 'AngularTimeout'),
			fromEvent(window, 'AngularReady'),
		)
			.subscribe(() => void this._ifNoBeaconMarkPageAsInvalid());
	}

	private _ifNoBeaconMarkPageAsInvalid(): void {
		if (this._$host.querySelector('[bp-scully-content-rendered-beacon]'))
			return;

		void this._metatagsService.setMetatags({ title: 'PAGE_INVALID_HAS_NO_CONTENT' });
	}
}
