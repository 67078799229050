<bp-modal>
	<ng-template>
		<div class="container">
			<div class="left-block">
				<bp-picture src="{{ assetsDir }}/black-hand" responsive skipPhone></bp-picture>
				<bp-picture src="{{ assetsDir }}/white-hand-white-card" responsive skipPhone></bp-picture>
				<bp-picture src="{{ assetsDir }}/white-hand-red-card" responsive skipPhone></bp-picture>
				<bp-picture src="{{ assetsDir }}/logo" responsive skipPhone></bp-picture>
			</div>

			<div class="main-block">
				<h4>Just a few more details<br />to get you started</h4>
				<bp-get-started-form cdkFocusInitial class="mb-4"></bp-get-started-form>
				<div class="join-business">Join global businesses and make your payments easy</div>
				<bp-companies-tiles></bp-companies-tiles>
			</div>
		</div>
	</ng-template>
</bp-modal>
