import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { SocialNetwork } from '../models';

@Pipe({
	name: 'socialShareLink',
})
export class SocialShareLinkPipe implements PipeTransform {

	transform(social: SocialNetwork, title: string, link: string = location.href): string {
		const encodedTitle = encodeURIComponent(title);
		const encodedUrl = encodeURIComponent(link);

		switch (social) {
			case SocialNetwork.facebook:
				return `https://www.facebook.com/sharer/sharer.php?u=${ encodedUrl }&quote=${ encodedTitle }`;

			case SocialNetwork.twitter:
				return `https://twitter.com/intent/tweet?text=${ encodedTitle }: ${ encodedUrl }`;

			case SocialNetwork.linkedin:
				return `https://www.linkedin.com/sharing/share-offsite/?url=${ encodedUrl }`;

			default:
				throw new Error('No such social network');
		}
	}

}
