<form class="promo-form" [formGroup]="form" (ngSubmit)="submit()">
	<mat-form-field>
		<mat-label>Full Name</mat-label>
		<input type="text" matInput autocomplete="name" formControlName="name" bpTrimOnPaste />
		<mat-error bpFieldError></mat-error>
	</mat-form-field>

	<mat-form-field>
		<mat-label>Work Email</mat-label>
		<input type="text" matInput autocomplete="email" formControlName="email" bpTrimOnPaste />
		<mat-error bpFieldError></mat-error>
	</mat-form-field>

	<mat-form-field>
		<mat-label>Company Website</mat-label>
		<input
			type="text"
			matInput
			autocomplete="website"
			placeholder="www.yourcompany.com"
			formControlName="companyWebsite"
			bpTrimOnPaste
		/>
		<mat-error bpFieldError></mat-error>
	</mat-form-field>

	<mat-form-field
		*ngIf="uiExperimentsService.showPhoneInputInsteadIndustryInput$ | async; else industryTpl"
		class="mb-1"
	>
		<mat-label>Phone Number</mat-label>
		<input type="text" matInput autocomplete="phone" formControlName="phone" [bpTextMask]="phoneMask" />
		<mat-error bpFieldError></mat-error>
	</mat-form-field>

	<ng-template #industryTpl>
		<mat-form-field class="mb-1">
			<mat-label>Industry</mat-label>
			<mat-select formControlName="industry">
				<mat-option *ngFor="let industry of pspIndustries" [value]="industry.name">
					{{ industry.displayName }}
				</mat-option>
			</mat-select>
			<mat-error bpFieldError></mat-error>
		</mat-form-field>
	</ng-template>

	<div *ngIf="error$ | async" class="global-error mb-4" @slide>Something is wrong, please, try later</div>

	<bp-pending-btn
		type="submit"
		btnClass="mat-flat-button promo-flat-button"
		[disabled]="form!.disabled"
		[pending]="submitting$ | async"
		bpGoogleTagOnClick="promo_get-started-form_cta_click"
	>
		{{ buttonText }}
	</bp-pending-btn>
</form>
