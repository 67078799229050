<mat-expansion-panel
	*ngrxLet="isGreaterThanTablet$; let isGreaterThanTablet"
	[hideToggle]="isGreaterThanTablet"
	[expanded]="isGreaterThanTablet"
	[disabled]="isGreaterThanTablet"
	class="mat-elevation-z0"
>
	<mat-expansion-panel-header>
		<mat-panel-title>
			<bp-picture src="{{ assetsDir }}shield" svg></bp-picture>

			<p class="mb-0">Your Details 100% Secure</p>
		</mat-panel-title>
	</mat-expansion-panel-header>

	<p>BridgerPay keeps all your details 100% secure so your sensitive information remains private.</p>

	<bp-picture src="{{ assetsDir }}pci" svg></bp-picture>
</mat-expansion-panel>
