<bp-submenu-item itemName="Company" popupClassPrefix="company" [dropdownMode]="dropdownMode">
	<a routerLinkNoOutlets="about-us" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
		<bp-picture class="bg-image" [src]="assetsDir + '/team'" responsive></bp-picture>
		<bp-svg-icon name="logo"></bp-svg-icon>
		<span>About Us</span>
	</a>

	<a routerLinkNoOutlets="newsroom" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
		<bp-picture class="bg-image" [src]="assetsDir + '/newsroom'" responsive></bp-picture>
		<span class="icon icon-newsroom">
			<bp-svg-icon name="logo"></bp-svg-icon>
		</span>
		<span>Newsroom</span>
	</a>

	<a routerLinkNoOutlets="careers" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
		<bp-picture class="bg-image" [src]="assetsDir + '/careers'" responsive></bp-picture>
		<span class="icon icon-careers"></span>
		<span>Careers</span>
		<bp-we-are-hiring-badge></bp-we-are-hiring-badge>
	</a>

	<a href="https://docs.bridgerpay.com" bpTargetBlank>
		<bp-picture class="bg-image" [src]="assetsDir + '/developers'" responsive></bp-picture>
		<span class="icon icon-developers"></span>
		<span>Developers</span>
	</a>
</bp-submenu-item>
