import { Injectable, NgZone } from '@angular/core';

type DataLayerEvent = {
	event: string;
	[key: string]: any;
};

@Injectable({ providedIn: 'root' })
export class GoogleOptimizeService {

	private get _dataLayer(): DataLayerEvent[] {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		return <DataLayerEvent[] | null>(<any>window).dataLayer ?? [];
	}

	constructor(private readonly _ngZone: NgZone) { }

	activate(): void {
		this._dispatchEvent('optimize.activate');
	}

	private _dispatchEvent(event: string, payload?: Object): void {
		this._ngZone.runOutsideAngular(
			() => this._dataLayer.push({ event, ...payload }),
		);
	}

}
