import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Validators } from '@bp/shared/features/validation';
import { MediaService } from '@bp/shared/features/media';
import { attrBoolValue } from '@bp/shared/utilities';
import { ZohoLeadService } from '@bp/shared/domains/zoho-lead';

import { IZohoLead } from '@bp/firebase-functions';

@Component({
	selector: 'bp-email-contact-form',
	templateUrl: './email-contact-form.component.html',
	styleUrls: [ './email-contact-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailContactFormComponent implements OnInit {

	@Input() noFloatLabel!: boolean | '';

	@Output() readonly submitted = new EventEmitter<string>();

	shortPlaceholder = 'Your email';

	fullPlaceholder = 'Enter your work email';

	emailCtrl = new UntypedFormControl('', [ Validators.customRequired('email'), Validators.email ]);

	constructor(
		public media: MediaService,
		private readonly _zohoLeadService: ZohoLeadService,
	) { }

	ngOnInit(): void {
		this.noFloatLabel = attrBoolValue(this.noFloatLabel);
	}

	submit(event: Event): void {
		event.preventDefault();

		if (this.emailCtrl.valid) {
			void this._saveLead();

			this.submitted.next(this.emailCtrl.value);
		} else {
			this.emailCtrl.markAsDirty();

			this.emailCtrl.markAsTouched();

			this.emailCtrl.updateValueAndValidity();
		}
	}

	private _saveLead(): void {
		const lead: IZohoLead = { email: (<string> this.emailCtrl.value).toLowerCase() };

		void this._zohoLeadService.saveLead(lead);
	}

}
