import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'bp-pending-animation',
	templateUrl: './pending-animation.component.html',
	styleUrls: [ './pending-animation.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendingAnimationComponent {
}
