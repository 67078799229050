<bp-carousel
	[items]="testimonials"
	[itemsPerViewport]="(mediaService.greaterThan$('Tablet') | async) ? 2 : 1"
	[arrowType]="CarouselArrowType.Circled"
	[arrowTemplate]="carouselArrow"
	arrowSize="lg"
>
	<ng-template let-item>
		<bp-carousel-testimonial
			*ngIf="ensureTestimonialDto(item) as item"
			[testimonial]="item"
			[class.logo]="item.logo"
		></bp-carousel-testimonial>
	</ng-template>
</bp-carousel>

<ng-template #carouselArrow>
	<img src="/assets/shared/arrow.svg" alt="arrow" />
</ng-template>
