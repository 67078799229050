import { clamp } from 'lodash-es';
import { distinctUntilChanged, fromEvent, map, startWith } from 'rxjs';
import { subscribeOn } from 'rxjs/operators';

import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { animate, animation, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';

import { IntercomService } from '@bp/shared/services';
import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import { BpScheduler } from '@bp/shared/rxjs';

import { ManualAnimationStrategy } from '@bp/promo/shared';

@Component({
	selector: 'bp-support',
	templateUrl: './support.component.html',
	styleUrls: [ './support.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportComponent extends Destroyable implements AfterViewInit {

	@ViewChild('media', { static: true, read: ElementRef }) media!: ElementRef<HTMLElement>;

	manualAnimationStrategy = new ManualAnimationStrategy();

	private readonly _supportImageAnimation = animation(
		[
			style({ transform: 'translateY(15px)', opacity: '0' }),
			animate('1.5s', style({ transform: 'translateY(0)', opacity: '1' })),
		],
	);

	constructor(
		public readonly intercomService: IntercomService,
		private readonly _animationBuilder: AnimationBuilder,
	) {
		super();
	}

	ngAfterViewInit(): void {
		this._animateSupportImages();
	}

	private _animateSupportImages(): void {
		const animationPlayer = this._buildAnimationPlayer();

		fromEvent(window, 'scroll')
			.pipe(
				subscribeOn(BpScheduler.outside),
				startWith(null),
				map(() => {
					// Note we need to recalculate it here cause of lazy load of images before
					const offsetTop = this.media.nativeElement.offsetTop - window.innerHeight / 4 * 3;
					const height = window.innerHeight / 3;

					return clamp((window.scrollY - offsetTop) / height, 0, 1);
				}),
				distinctUntilChanged(), // Filter multiple 0's or 1's
				takeUntilDestroyed(this),
			)
			.subscribe(fraction => {
				void animationPlayer.setPosition(fraction);

				if (fraction === 0)
					this.manualAnimationStrategy.resetAndStop();

				if (fraction === 1)
					this.manualAnimationStrategy.play();
			});
	}

	private _buildAnimationPlayer(): AnimationPlayer {
		const animationPlayer = this._animationBuilder
			.build(this._supportImageAnimation)
			.create(this.media.nativeElement);

		animationPlayer.play();

		animationPlayer.pause();

		return animationPlayer;
	}

}
