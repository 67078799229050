import { animate, animation, keyframes, style, transition, trigger, useAnimation } from '@angular/animations';

import { NORMAL, TIMINGS } from '@bp/shared/animations';

export const semiFadeIn = animation(animate(
	TIMINGS,
	keyframes([
		style({ opacity: 0 }),
		style({ opacity: '*' }),
	]),
));

export const semiFadeOut = animation(animate(
	TIMINGS,
	keyframes([
		style({ opacity: '*' }),
		style({ opacity: 0 }),
	]),
));

export const SEMI_FADE = trigger('semiFade', [
	transition(':enter', useAnimation(semiFadeIn, NORMAL)),
	transition(':leave', useAnimation(semiFadeOut, NORMAL)),
]);
