<h2 class="headliner uppercase">
	OPERATE ALL YOUR&nbsp;<bp-lottie-scene sceneName="home-atf-sliding-items" looped></bp-lottie-scene>&nbsp;PAYMENTS ON
	ONE PLATFORM
</h2>

<p>Centralized dashboard and reports to make better and faster payment processing decisions.</p>

<a mat-flat-button class="promo-flat-button" routerLink="/product" color="primary"> Learn More </a>

<div class="animation">
	<bp-lottie-scene sceneName="home-operations-tooltip" looped></bp-lottie-scene>
	<bp-lottie-scene
		*bpIfGreaterThan="'PhoneLg'; else phoneTpl"
		sceneName="home-operations-stripes"
		eager
		looped
	></bp-lottie-scene>

	<ng-template #phoneTpl>
		<bp-lottie-scene
			sceneName="home-operations-stripes-phone"
			class="home-operations-stripes"
			looped
		></bp-lottie-scene>
	</ng-template>
</div>
