import { Component, ChangeDetectionStrategy, Input, ContentChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FADE_IN, SLIDE } from '@bp/shared/animations';
import { OnChanges, SimpleChanges } from '@bp/shared/models/core';

import { YoutubeHelper } from '../../utils';
import { YoutubePlayerDialogData, YoutubePlayerDialogComponent } from '../youtube-player-dialog';
import { CustomThumbnailDirective } from '../../directives';

@Component({
	selector: 'bp-youtube-player[src]',
	templateUrl: './youtube-player.component.html',
	styleUrls: [ './youtube-player.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN, SLIDE ],
})
export class YoutubePlayerComponent implements OnChanges {

	@Input() src!: string;

	@Input() autoplay = false;

	@Input() loop = false;

	@Input() mode: 'inline' | 'modal' = 'inline';

	@ContentChild(CustomThumbnailDirective) customThumbnail?: CustomThumbnailDirective;

	showPlayer!: boolean;

	get embedVideoUrl(): string {
		return this._buildEmbedVideoUrl();
	}

	get fullHDThumbnailUrl(): string {
		return YoutubeHelper.getFullHDThumbnailUrl(this._videoId);
	}

	isIframeLoaded = false;

	private _videoId!: string;

	constructor(private readonly _matDialog: MatDialog) {
	}

	ngOnChanges({ src, autoplay }: SimpleChanges<this>): void {
		if (src)
			this._setVideoIdBasedOnSrc();

		if (autoplay)
			this.showPlayer = this.autoplay;
	}

	onThumbnailClick(): void {
		if (this.mode === 'modal') {
			this._matDialog.open<YoutubePlayerDialogComponent, YoutubePlayerDialogData>(
				YoutubePlayerDialogComponent, {
					data: {
						src: this.src,
						loop: this.loop,
						customThumbnailTpl: this.customThumbnail?.templateRef,
					},
				},
			);
		} else
			this.showPlayer = true;
	}

	private _setVideoIdBasedOnSrc(): void {
		if (this.src.startsWith('http')) {
			const extractedId = YoutubeHelper.extractVideoIdFromUrl(this.src);

			if (extractedId)
				this._videoId = extractedId;
			else
				throw new Error('Invalid youtube video url was provided');
		} else
			this._videoId = this.src;
	}

	private _buildEmbedVideoUrl(): string {
		const youtubeUrl = new URL(`https://www.youtube.com/embed/${ this._videoId }`);
		const searchParams = new URLSearchParams({
			autoplay: '1',
			mute: '1',
			loop: this.loop ? '1' : '0',
			version: '3',
			modestbranding: '1',
			vq: 'hd1080',
			quality: 'high',
			...(this.loop ? { playlist: this._videoId } : {}),
		});

		youtubeUrl.search = searchParams.toString();

		return youtubeUrl.toString();
	}

}
