import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'bp-companies',
	templateUrl: './companies.component.html',
	styleUrls: [ './companies.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompaniesComponent {
}
