import { fromEvent, lastValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { $ } from '@bp/shared/utilities';
import { AsyncVoidSubject } from '@bp/shared/rxjs';
import { EnvironmentService, TelemetryService } from '@bp/shared/services';

import { IShowSurveyDisplayOptions, ISurvicate, ISurvicateTraits } from '../models';

@Injectable({
	providedIn: 'root',
})
export class SurvicateService {

	private _survicate?: ISurvicate;

	private readonly _enabled = !!this._environmentService.survicate;

	private readonly _isSurvicateReady$ = new AsyncVoidSubject();

	private _inited = false;

	private readonly _windowPropertyServiceName = 'BP_SurvicateService';

	constructor(
		private readonly _environmentService: EnvironmentService,
	) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		(<any> window)[this._windowPropertyServiceName] = this;

		if (this._enabled)
			this._listenToSurvicateReadyEvent();
	}

	init(): void {
		if (this._inited || !this._enabled)
			return;

		TelemetryService.log('Init Survicate');

		this._injectSurvicateScript();

		this._inited = true;
	}

	async retarget(): Promise<void> {
		if (!this._enabled)
			return;

		await this._waitForSurvicate();

		this._survicate!.retarget();
	}

	async getVisitorId(): Promise<string | null> {
		if (!this._enabled)
			return null;

		await this._waitForSurvicate();

		return this._survicate!.getVisitorId() ?? null;
	}

	async setVisitorTraits(options: ISurvicateTraits): Promise<void> {
		if (!this._enabled)
			return;

		await this._waitForSurvicate();

		this._survicate!.setVisitorTraits(options);
	}

	async destroyVisitor(): Promise<void> {
		if (!this._enabled)
			return;

		await this._waitForSurvicate();

		return new Promise(resolve => this._survicate?.destroyVisitor(resolve));
	}

	async showSurvey(surveyId: string, options?: IShowSurveyDisplayOptions): Promise<boolean | null> {
		if (!this._enabled)
			return null;

		await this._waitForSurvicate();

		return this._survicate!.showSurvey(surveyId, options);
	}

	async destroyAndRetargetVisitor(): Promise<void> {
		if (!this._enabled)
			return;

		await this._waitForSurvicate();

		await this.destroyVisitor();

		void this.retarget();
	}

	private _listenToSurvicateReadyEvent(): void {
		fromEvent(window, 'SurvicateReady')
			.pipe(first())
			.subscribe(() => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
				this._survicate = (<any>window)._sva;

				this._isSurvicateReady$.complete();
			});
	}

	private async _waitForSurvicate(): Promise<void> {
		await lastValueFrom(this._isSurvicateReady$);
	}

	private _injectSurvicateScript(): void {
		document.body.append($.buildAsyncScriptElement({
			src: `https://survey.survicate.com/workspaces/${ this._environmentService.survicate }/web_surveys.js`,
		}));
	}
}
