import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MediaService } from '@bp/shared/features/media';

import { ROUTE_ANIMATED_CLASS } from '@bp/promo/shared';

@Component({
	selector: 'bp-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: [ './home-page.component.scss' ],
	host: {
		class: ROUTE_ANIMATED_CLASS,
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent {

	constructor(public readonly mediaService: MediaService) {
	}

}
