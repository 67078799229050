<mat-progress-bar
	*ngIf="showPlayer && !isIframeLoaded"
	@slide
	class="progress-bar-top-sticked"
	color="primary"
	mode="indeterminate"
>
</mat-progress-bar>

<div class="grid-stacked-children">
	<div class="video-thumbnail" [class.show-playback-icon]="!showPlayer" (click)="onThumbnailClick()">
		<ng-container
			*ngIf="customThumbnail?.templateRef as templateRef; else fullHDThumbnail"
			[ngTemplateOutlet]="templateRef"
		></ng-container>

		<ng-template #fullHDThumbnail>
			<img [src]="fullHDThumbnailUrl" />
		</ng-template>
	</div>

	<iframe
		*ngIf="showPlayer"
		@fadeIn
		allow="fullscreen"
		frameborder="0"
		[src]="embedVideoUrl | safe: 'resourceUrl'"
		(load)="isIframeLoaded = true"
	>
	</iframe>
</div>

<ng-content></ng-content>
