import fastdom from 'fastdom';
import { Observable } from 'rxjs';
import { subscribeOn } from 'rxjs/operators';

import { BpScheduler } from '../schedulers/schedulers';

export function fromMutate(mutate: () => void): Observable<void> {
	return new Observable<void>(subscriber => {
		const id = fastdom.mutate(() => {
			mutate();

			subscriber.next();

			subscriber.complete();
		});

		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		(<any> fastdom).catch = (error: any) => void subscriber.error(error);

		return () => fastdom.clear(id);
	})
		.pipe(subscribeOn(BpScheduler.outside));
}
