import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ModalHostComponent } from '@bp/shared/features/modal';

@Component({
	selector: 'bp-get-started-modal',
	templateUrl: './get-started-modal.component.html',
	styleUrls: [ './get-started-modal.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetStartedModalComponent extends ModalHostComponent {

	assetsDir = '/assets/shared/get-started-modal';

	override panelClass = [ 'get-started-modal' ];

}
