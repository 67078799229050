import type { FormGroupConfig } from '@bp/shared/typings';
import { Validators } from '@bp/shared/features/validation';

import type { IZohoLead } from '@bp/firebase-functions';

const zohoLeadFormBaseConfig = <FormGroupConfig<IZohoLead>> {
	name: [ '', Validators.customRequired('fullName') ],
	email: [ '', [ Validators.customRequired('email'), Validators.email ]],
	companyWebsite: [ '', [ Validators.customRequired('companyWebsite'), Validators.urlWithOptionalProtocol ]],
};

export const zohoLeadFormIndustryConfig = <FormGroupConfig<IZohoLead>> {
	...zohoLeadFormBaseConfig,
	industry: [ null, Validators.customRequired('industry') ],
};

export const zohoLeadFormPhoneConfig = <FormGroupConfig<IZohoLead>> {
	...zohoLeadFormBaseConfig,
	phone: [ '' ],
};

export const zohoLeadGeneralLandingFormConfig = <FormGroupConfig<IZohoLead>> {
	...zohoLeadFormIndustryConfig,
	phone: [ '' ],
};
