import { Directive, Input } from '@angular/core';

import { TooltipDirective } from '@bp/shared/features/tooltip';

@Directive({
	selector: '[bpPromoTooltip]',
})
export class PromoTooltipDirective extends TooltipDirective {

	@Input('bpPromoTooltip') override content?: string | '' | null = null;

	override arrow = true;

	override theme = 'light-border';

}
