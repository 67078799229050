import { Enumeration } from '@bp/shared/models/core/enum';

export class SocialNetwork extends Enumeration {

	static facebook = new SocialNetwork();

	static linkedin = new SocialNetwork();

	static twitter = new SocialNetwork();

}
