import type { IAppEnvironment } from '@bp/shared/typings';

export const environment: Partial<IAppEnvironment> = {
	api: 'https://api.bridgerpay.com/api',
	apiVersion: 'v2',
	sentry: 'https://4de650d16bb14fbfaa2c7a9fe49eb4c0@o205478.ingest.sentry.io/5456168',
	wizardUrl: 'https://signup.bridgerpay.com',
	intercom: 'ff289wc0',
	logrocket: 'fpkaz5/prod-promo-website',
	googleTagAnalyticsId: 'G-97LS2J27FZ',
	survicate: 'bf68d08aadc0e7905b0cc93e3c907b69',
};
