import { camelCase, get, has, isArray, isNil, set } from 'lodash-es';

import type { Type } from '@angular/core';

import { Constructable, PrimitiveConstructable } from '@bp/shared/models/core';
import { Enumeration } from '@bp/shared/models/core/enum';
import type { Dictionary } from '@bp/shared/typings';
import { hasOwnProperty, isEmpty, isExtensionOf } from '@bp/shared/utilities';

import { ClassMetadata } from './class-metadata';
import type { PropertyMapper, PropertyMapperFunction, PropertyMetadata } from './property-metadata';

export abstract class MetadataEntity extends Constructable {

	private static readonly _metadata: ClassMetadata<any>;

	static getClassMetadata<T extends typeof MetadataEntity>(this: T): ClassMetadata<InstanceType<T>> {
		if (has(this, '_metadata'))
			return this._metadata;

		// @ts-expect-error we need to treat metadata as readonly in any way
		return (this._metadata = new ClassMetadata<InstanceType<T>>(this));
	}

	get classMetadata(): ClassMetadata<this> {
		return <any>(<typeof MetadataEntity> this.constructor).getClassMetadata();
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	constructor(instanceDTO?: any) {
		super();

		this._setPropertiesAttributes();

		if (instanceDTO) {
			this._invokePropertyMappers(
				this._tryMergeSourceOfDTOIntoInstanceDTO(instanceDTO),
			);
		}

		this._setDefaultPropertiesValues();

		this._setSymbols();

		this._createPropertiesAliases();
	}

	private _tryMergeSourceOfDTOIntoInstanceDTO(instanceDTO: Dictionary<any>): Dictionary<any> {
		const sourcesOfDTOMetadata = this.classMetadata
			.values
			.filter(v => v.sourceOfDTO);

		if (isEmpty(sourcesOfDTOMetadata))
			return instanceDTO;

		this._assertSourceOfDTODecoratorMetadata(sourcesOfDTOMetadata);

		const [ sourceOfDTOMetadata ] = sourcesOfDTOMetadata;

		if (!this._isFunctionMapper(sourceOfDTOMetadata.mapper!))
			throw new Error(`Only a function mapper is allowed for  ${ sourceOfDTOMetadata.property }`);

		return {
			...instanceDTO,
			...sourceOfDTOMetadata.mapper(
				instanceDTO[sourceOfDTOMetadata.property],
				instanceDTO,
				this,
			),
		};
	}

	private _assertSourceOfDTODecoratorMetadata(sourceOfDTOMetadata: PropertyMetadata[]): void {
		if (sourceOfDTOMetadata.length > 1)
			throw new Error('Only one SourceOfDTO decorator can be declared per class');
	}

	private _invokePropertyMappers(instanceDTO: Dictionary<any>): void {
		this.classMetadata
			.values
			.filter(v => !v.sourceOfDTO)
			.forEach(propertyMetadata => this._isMetadataMapperAndDTOValueValidForMapping(propertyMetadata, instanceDTO)
				? void this._applyMetadataMapperToDTOValueAndSetToInstance(propertyMetadata, instanceDTO)
				: void this._setDTOValueAsIsToInstance(propertyMetadata, instanceDTO));
	}

	private _isMetadataMapperAndDTOValueValidForMapping(
		{ mapper, property, alias }: PropertyMetadata, instanceDTO: Dictionary<any>,
	): boolean {
		return !!mapper && (!isNil(instanceDTO[alias!]) || !isNil(instanceDTO[property]));
	}

	private _setDTOValueAsIsToInstance({ property, alias }: PropertyMetadata, instanceDTO: Dictionary<any>): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const dtoAliasPropertyValue = get(instanceDTO, alias!);

		if (alias && hasOwnProperty(instanceDTO, alias) && dtoAliasPropertyValue)
			set(this, property, dtoAliasPropertyValue);
 		 else if (hasOwnProperty(instanceDTO, property))
			set(this, property, get(instanceDTO, property));
	}

	private _applyMetadataMapperToDTOValueAndSetToInstance(
		{ mapper, property, alias }: PropertyMetadata,
		 instanceDTO: Dictionary<any>,
	): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const dtoPropertyValue = (alias ? get(instanceDTO, alias) : null) ?? get(instanceDTO, property);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const mappedPropertyValue = isArray(dtoPropertyValue) && !this._isFunctionMapper(mapper!)
			? dtoPropertyValue.map(dtoValue => this._invokeInferredMapper(property, mapper!, dtoValue, instanceDTO))
			: this._invokeInferredMapper(property, mapper!, dtoPropertyValue, instanceDTO);

		set(this, property, mappedPropertyValue);
	}

	private _invokeInferredMapper(property: string, mapper: PropertyMapper, dtoPropertyValue: any, instanceDTO: Dictionary<any>): any {
		if (this._isFunctionMapper(mapper))
			return mapper(dtoPropertyValue, instanceDTO, this);

		if (this._isEnumMapper(mapper))
			return this._invokeEnumMapper(property, mapper, dtoPropertyValue);

		if (this._isConstructableMapper(mapper))
			return new mapper(dtoPropertyValue);

		throw new Error('Unsupported metadata entity property mapper');
	}

	private _isFunctionMapper(mapper: PropertyMapper): mapper is PropertyMapperFunction {
		return Object.getPrototypeOf(mapper) === Object.getPrototypeOf(Function);
	}

	private _isEnumMapper(mapper: PropertyMapper): mapper is typeof Enumeration {
		return isExtensionOf(mapper, Enumeration);
	}

	private _isConstructableMapper(mapper: PropertyMapper): mapper is Type<Constructable> {
		return isExtensionOf(mapper, Constructable) || isExtensionOf(mapper, PrimitiveConstructable);
	}

	private _invokeEnumMapper(property: string, enumeration: typeof Enumeration, dtoPropertyValue: any): Enumeration | null {
		if (isNil(dtoPropertyValue))
			return null;

		const enumValue = enumeration.parse(camelCase(dtoPropertyValue));

		if (isNil(enumValue))
			console.error(`${ property }: invalid enum value received ${ dtoPropertyValue }`);

		return enumValue;
	}

	private _setPropertiesAttributes(): void {
		this.classMetadata.values
			.filter(v => v.unserializable)
			.forEach(v => Object.defineProperty(this, v.property, {
				enumerable: false,
				configurable: true,
				writable: true,
			}));
	}

	private _setDefaultPropertiesValues(): void {
		this.classMetadata.values
			.filter(propertyMetadata => propertyMetadata.defaultPropertyValue !== undefined
				&& isNil(get(this, propertyMetadata.property)))
			.forEach(propertyMetadata => set(
				this,
				propertyMetadata.property,
				propertyMetadata.defaultPropertyValue,
			));
	}

	private _setSymbols(): void {
		this.classMetadata.values
			.filter(({ symbolize }) => symbolize !== null)
			.forEach(({ property, symbolize }) => set(
				this,
				property,
				Symbol(`${ symbolize }_${ this.constructor.name }_${ property }`),
			));
	}

	private _createPropertiesAliases(): void {
		this.classMetadata.values
			.filter(({ alias }) => !!alias)
			.forEach(({ property, alias }) => Object.defineProperty(
				this,
				alias!,
				{
					get: () => get(this, property),
					enumerable: true,
				},
			));
	}

}
