import type { TestimonialDto } from '../models';

import { TESTIMONIALS_ASSETS_DIR, LOGOS_ASSETS_DIR } from './paths';

export const TESTIMONIALS: TestimonialDto[] = [
	{
		name: 'Bryn Newell',
		position: 'CTO, Eightcap',
		face: '',
		face2x: '',
		logo: `${ LOGOS_ASSETS_DIR }/eightcap.png`,
		logo2x: `${ LOGOS_ASSETS_DIR }/eightcap@2x.png`,
		rate: 5,
		text: 'BridgerPay\'s pre-built PSP integrations have enabled Eightcap to seamlessly scale payment solutions at speed to meet customer demand. We have been pleased with the customer service throughout onboarding, and the continued support as new PSPs are implemented.',
	},
	{
		name: 'Charis Neoptolemou',
		position: 'Director, CSMoney',
		face: `${ TESTIMONIALS_ASSETS_DIR }/neoptolemou.png`,
		face2x: `${ TESTIMONIALS_ASSETS_DIR }/neoptolemou@2x.png`,
		logo: `${ LOGOS_ASSETS_DIR }/csmoney.png`,
		logo2x: `${ LOGOS_ASSETS_DIR }/csmoney@2x.png`,
		rate: 5,
		text: `We have an excellent business relationship with BridgerPay in all aspects that we cooperate.
		       The team is very helpful and always reply promptly to our requirements and provide to our company the best possible
		       assistance and advice. We look forward to continue our business cooperation in the future based on mutual respect and
		       understanding taking into consideration all the relevant parameters that involve the operation of our Company.`,
		shortText: 'We have an excellent business relationship with BridgerPay in all aspects that we cooperate. The team is very helpful and always reply promptly to our requirements and provide to our company the best possible assistance and advice. We look forward to continue our business cooperation.',
	},
	{
		name: 'Maurice V.',
		position: 'Ecommerce IT solutions specialist, WooCommerce',
		face: `${ TESTIMONIALS_ASSETS_DIR }/maurice-replacer.png`,
		face2x: `${ TESTIMONIALS_ASSETS_DIR }/maurice-replacer@2x.png`,
		logo: `${ LOGOS_ASSETS_DIR }/woo-commerce.png`,
		logo2x: `${ LOGOS_ASSETS_DIR }/woo-commerce@2x.png`,
		rate: 5,
		text: `The powerful BridgerPay Payments routing solution integration is the cornerstone of my great solution that I provide to my
		       clients that use it for Marketing & Sales alignment. It allows me to have a single source of truth for my clients’ data and
		       accurately measure the impact it gives for marketing campaigns on the sales pipeline.`,
		shortText: `The powerful BridgerPay Payments routing solution integration is the cornerstone of my great solution that I provide to my
		       clients that use it for Marketing & Sales alignment. It allows me to have a single source of truth for my clients’ data and
		       accurately measure the impact it gives.`,
	},
	{
		name: 'Thomas Boateng',
		position: 'Founder, Thombo Foundation',
		face: '',
		face2x: '',
		logo: `${ LOGOS_ASSETS_DIR }/thombo-foundation.png`,
		logo2x: `${ LOGOS_ASSETS_DIR }/thombo-foundation@2x.png`,
		rate: 5,
		text: 'We know our goal is ambitious, but everyday we are getting a little bit closer. Thanks to BridgerPay, now we can accept local and cross-border donations with ease and this will help us plant more trees faster and restore our beautiful forests.',
	},
	{
		name: 'Kannan Rajaratnam',
		position: 'Regional Head of Payments, Zalora',
		face: `${ TESTIMONIALS_ASSETS_DIR }/kannan.png`,
		face2x: `${ TESTIMONIALS_ASSETS_DIR }/kannan@2x.png`,
		logo: `${ LOGOS_ASSETS_DIR }/zalora.png`,
		logo2x: `${ LOGOS_ASSETS_DIR }/zalora@2x.png`,
		rate: 5,
		text: `Using BridgerPay didn't just pay off, the ROI is absolutely unbelievable. Everything about BridgerPay is excellent.
		       Speed of deployment, customization, customer care, and ease of use are top-notch. A platform like this is definitely new
		       and one of a kind. I’m glad that we approached BridgerPay, I am extremely pleased by the work done so far, and I can’t wait
		       to keep using BridgerPay in the future`,
		videoId: 'ee8wqNyZIQk',
	},
	{
		name: 'Kai Ling Ting',
		position: 'Head of Innovation Etihad Aviation Group',
		face: `${ TESTIMONIALS_ASSETS_DIR }/kai-ling.png`,
		face2x: `${ TESTIMONIALS_ASSETS_DIR }/kai-ling@2x.png`,
		logo: `${ LOGOS_ASSETS_DIR }/etihad.png`,
		logo2x: `${ LOGOS_ASSETS_DIR }/etihad@2x.png`,
		rate: 5,
		text: `BridgerPay impressed us with their plug and play solution which required only a one-time integration that would allow us the
		       ability to unlock and customize unlimited payment options in multiple currencies, and the user-friendly admin dashboard which
		       would allow us to manage payment reconciliations as well as refunds with ease.`,
		shortText: 'BridgerPay impressed us with their plug and play solution which required only a one-time integration that would allow us the ability to unlock and customize unlimited payment options in multiple currencies, and the user-friendly admin dashboard which would allow us to manage.',
	},
];
