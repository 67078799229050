<button class="upload-link text-ellipsis reset-default-decoration" type="button">
	<span class="link">
		<span *ngIf="file?.name; else defaultTextTpl">{{ file!.name }}</span>
		<ng-template #defaultTextTpl>
			<ng-content></ng-content>
		</ng-template>
	</span>
</button>

<div *ngIf="isExceededAllowedSize || (firebaseService.uploadError$ | async) !== null" @slide class="error-container">
	<div class="error">
		{{ firebaseService.uploadError$ | async }}
		<span *ngIf="isExceededAllowedSize">The maximum allowed size of the file is 15mb</span>
	</div>
</div>

<input
	#filePicker
	type="file"
	accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
/>
