<div *ngIf="(cookiesUsageConsentService.consented$ | async) === false && !isScullyRunning" @fade class="global-overlay">
	<div class="popup mat-body">
		<button type="button" mat-icon-button class="btn-icon btn-close" (click)="cookiesUsageConsentService.consent()">
			<mat-icon>close</mat-icon>
		</button>

		<bp-picture src="/assets/cookies-usage-consent-popup/cookie" svg></bp-picture>

		<div class="consent-message">
			We use cookies to make sure you have the best experience on our site. By clicking “Got it” or exiting this
			banner, you accept our use of cookies.
		</div>

		<button
			type="button"
			mat-flat-button
			class="promo-flat-button promo-md-button got-it-btn"
			color="primary"
			(click)="cookiesUsageConsentService.consent()"
		>
			Got it
		</button>
	</div>
</div>
