import { Injectable } from '@angular/core';

import { MetatagsService, RouteMetatags } from '@bp/shared/features/metatags';
import {
	EnvironmentService, IntercomService, MomentTimezoneService, ProgressiveWebAppService, SharedStartupService
} from '@bp/shared/services';
import { AnalyticsService, GoogleTagService, UtmService } from '@bp/shared/features/analytics';
import { Platform } from '@bp/shared/typings';
import { SurvicateService } from '@bp/shared/features/survicate';
import { takeFirstTruthy } from '@bp/shared/rxjs';

import { BRIDGERPAY_ABOUT_US_DESCRIPTION, ScullyService, WizardConnectService } from '@bp/promo/shared';

import { CookiesUsageConsentService } from './core';

@Injectable({
	providedIn: 'root',
})
export class AppStartupService {

	readonly platform: Platform = 'promo';

	constructor(
		private readonly _sharedStartupService: SharedStartupService,
		private readonly _utmService: UtmService,
		private readonly _metatagsService: MetatagsService,
		private readonly _environment: EnvironmentService,
		private readonly _cookiesUsageConsentService: CookiesUsageConsentService,
		private readonly _momentTimezoneService: MomentTimezoneService,
		private readonly _intercomService: IntercomService,
		private readonly _pwaService: ProgressiveWebAppService,
		private readonly _googleTagService: GoogleTagService,
		private readonly _wizardConnectService: WizardConnectService,
		private readonly _scullyService: ScullyService,
		private readonly _analyticsService: AnalyticsService,
		private readonly _survicateService: SurvicateService,
	) {
	}

	init(): void {
		this._sharedStartupService.init();

		this._momentTimezoneService.setGuessedTimezone();

		this._utmService.init();

		this._tryLockInPortraitOrientation();

		this._setDefaultMetatagsForAllRoutes();

		this._initAnalyticsKindServicesOnCookiesUsageConsent();

		this._pwaService.whenNewVersionAvailableReloadApp();

		this._scullyService.init();

		this._setAnalyticsGlobalParams();
	}

	private _setDefaultMetatagsForAllRoutes(): void {
		this._metatagsService.init(
			this._environment.isProduction ? 'bridgerpay.com' : 'bridgerpay.dev',
			new RouteMetatags({
				// eslint-disable-next-line @typescript-eslint/naming-convention
				site_name: 'BridgerPay | Payment Operations Platform',
				description: BRIDGERPAY_ABOUT_US_DESCRIPTION,
				image: '/assets/default-meta-img.png',
				twitter: {
					site: '@bridgerpay',
				},
			}),
		);
	}

	private _tryLockInPortraitOrientation(): void {
		try {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			window.screen.orientation.lock('portrait').catch(() => { });
		} catch {

		}
	}

	private _initAnalyticsKindServicesOnCookiesUsageConsent(): void {
		this._cookiesUsageConsentService.consented$
			.pipe(takeFirstTruthy)
			.subscribe(() => {
				void this._intercomService.init();

				void this._analyticsService.init();

				void this._survicateService.init();
			});
	}

	private _setAnalyticsGlobalParams(): void {
		this._googleTagService.setGlobalVariables({
			wizardSetupId: this._wizardConnectService.wizardConfigId ?? undefined,
		});
	}

}
