<ng-container *ngIf="hasHeader">
	<bp-svg-icon name="logo"></bp-svg-icon>

	<h1 class="mat-headline uppercase text-center">{{ title }}</h1>
</ng-container>

<div class="before-content">
	<ng-content select=".before-content"></ng-content>
</div>

<div class="content longread-content-typography">
	<ng-content></ng-content>
</div>
