import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'bp-companies-tiles-phone',
	templateUrl: './companies-tiles-phone.component.html',
	styleUrls: [ './companies-tiles-phone.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompaniesTilesPhoneComponent {

	assetsDir = '/assets/home/companies/companies-tiles-line';

}
