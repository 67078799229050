import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'bp-for-devs-pricing',
	templateUrl: './for-devs-pricing.component.html',
	styleUrls: [ './for-devs-pricing.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForDevsPricingComponent {

	assetsDir = '/assets/home/for-devs-pricing';

}
