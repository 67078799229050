<div class="grid">
	<div class="left">
		<bp-youtube-player class="player" mode="modal" [src]="videoTestimonial.videoId">
			<div *bpCustomThumbnail class="zalora-thumbnail">
				<img class="zalora-thumbnail-image w-100" [src]="zaloraPreviewThumbnail$ | async" />

				<img class="zalora-title" src="{{ assetsDir }}zalora-title.png" />

				<span class="zalora-description">How ZALORA Improves Its PSP Offering With BridgerPay</span>
			</div>
		</bp-youtube-player>

		<ng-container
			*ngFor="let testimonial of leftTextTestimonials$ | async"
			[ngTemplateOutlet]="testimonialTpl"
			[ngTemplateOutletContext]="{testimonial}"
		></ng-container>
	</div>

	<div class="right">
		<ng-container
			*ngFor="let testimonial of rightTextTestimonials$ | async"
			[ngTemplateOutlet]="testimonialTpl"
			[ngTemplateOutletContext]="{ testimonial }"
		></ng-container>

		<div class="info-block">
			<div class="update mb-4">
				<bp-picture src="{{ assetsDir }}rock-sign" class="mr-2" svg></bp-picture>

				<span class="ng-tns-c345-8">UPDATE</span>
			</div>

			<div class="title mb-2">Card Fraud: ECB Report and Orchestration as a Safety Tool</div>

			<a routerLink="/newsroom/blog/ohpKLuLFZ9hSptDPk7tO" class="link">Read More</a>

			<bp-picture class="guy-staring-at-phone" src="{{ assetsDir }}guy-staring-at-phone" eager></bp-picture>
		</div>
	</div>
</div>

<ng-template #testimonialTpl let-testimonial="testimonial">
	<div class="testimonial">
		<img class="logo mb-1" srcset="{{testimonial.logo}} 1x, {{testimonial.logo2x}} 2x" />
		<p class="mb-2">“{{ testimonial.shortText ?? testimonial.text }}”</p>
		<p class="mb-0">
			<strong>
				<span class="mr-1">{{ testimonial.name }}</span>
				|
				<span class="ml-1">{{ testimonial.position }}</span>
			</strong>
		</p>
	</div>
</ng-template>
